import {PedidoItem} from "../models/pedido_item.model";
import {mul, sub} from "./math_util";

export const calcTotalItem = (item: PedidoItem) => {
    return sub(calcSubtotalItem(item), item.valorDesconto)
}

export const calcSubtotalItem = (item: PedidoItem) => {
    return mul(item.quantidade, item.valorUnitario)
}
