export class OpvcCondPagto {
    Id: number = 0
    Valor: number = 0
    Descricao: string = ''
    CodigoBandeira: string = ''
    NrAutorizacao: string = ''
    Nsu: string = ''
    Rede: string = ''
    ValorPago: number = 0
    QuantidadeParcela: number = 0
    IdContaCaixa: any = ''
    IdMeioPagamento: any = ''
    IdOpvc: any = ''
    IdCondPagto: any = ''
    //OpvcCondPagtoParcelaList: any[] = []

    static relacionamentos() {
        return ['IdContaCaixa', 'IdMeioPagamento', 'IdCondPagto']
    }

    static referencias() {
        return [{chave: 'IdContaCaixa', referencia: 'ContaCaixa'}, {chave: 'IdMeioPagamento', referencia: 'MeiosPagamento'}, {chave: 'IdCondPagto', referencia: 'CondicoesPagamento'}]
    }

    static exclude() {
        return ['PermiteTroco']
    }
}
