import {Action} from "../../models/action.model";
import {all, call, put, takeEvery} from "redux-saga/effects";
import {Produto} from "../../models/produto.model";
import {PedidoItem} from "../../models/pedido_item.model";
import api from "../../config/network";
import {URL_BANCO_STORAGE_KEY} from "../../config/apiConstants";

export const Types = {
    CHANGE_MODAL_VISIBILITY: 'pesquisaPreco/CHANGE_VISIBILITY',
    OPEN_MODAL_PESQUISA: 'pesquisaPreco/OPEN_MODAL_PESQUISA',
    CLOSE_MODAL: 'pesquisaPreco/CLOSE_MODAL',
    PUT_PRODUCTS: 'pesquisaPreco/PUT_PRODUCTS',
    CHANGE_INPUT_VALUE: 'pesquisaPreco/CHANGE_INPUT_VALUE',
    SEARCH_BEGIN: 'pesquisaPreco/SEARCH_BEGIN',
    SEARCH_SUCCESS: 'pesquisaPreco/SEARCH_SUCCESS',
}

export interface PesquisaPrecoTypes {
    modalAberto: boolean
    apenasPesquisa: boolean
    produtos: Produto[]
    inputValue: string
}

const INITIAL_STATE: PesquisaPrecoTypes = {
    modalAberto: false,
    apenasPesquisa: false,
    produtos: [],
    inputValue: '',
}

export const PesquisaPrecoCreators = {
    changeModalVisible: () => ({type: Types.CHANGE_MODAL_VISIBILITY}),
    openModalPesquisa: () => ({type: Types.OPEN_MODAL_PESQUISA}),
    closeModal: () => ({type: Types.CLOSE_MODAL}),
    putProducts: (produtos: Produto[]) => ({type: Types.PUT_PRODUCTS, payload: produtos}),
    changeInputValue: (text: string) => ({type: Types.CHANGE_INPUT_VALUE, payload: text}),
    searchProductBegin: (value: any) => ({type: Types.SEARCH_BEGIN, payload: value}),
    searchProductSuccess: (value: PedidoItem) => ({type: Types.SEARCH_SUCCESS, payload: value}),
}

export const pesquisaPreco = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case Types.CHANGE_MODAL_VISIBILITY:
            // @ts-ignore
            const apenasPesquisa = state.modalAberto
            return {...state, modalAberto: !state.modalAberto, apenasPesquisa}
        case Types.OPEN_MODAL_PESQUISA:
            return {...state, modalAberto: true, apenasPesquisa: true}
        case Types.CLOSE_MODAL:
            return {...state, modalAberto: false, apenasPesquisa: false, produtos: []}
        case Types.PUT_PRODUCTS:
            return {...state, produtos: action.payload}
        case Types.CHANGE_INPUT_VALUE:
            return {...state, inputValue: action.payload}
        case Types.SEARCH_SUCCESS:
            return {...state, produtos: action.payload}
        default:
            return state
    }
}


function* procurarProduto({payload}: Action) {
    try {
        let {data} = yield call(api.get, `/cad/${sessionStorage.getItem(URL_BANCO_STORAGE_KEY)}/produtos/produtos?Texto='${payload.codigo}'&CampoOrdem=Nome&Pagina=0&Limite=1000`)
        data = data.value
            yield put(PesquisaPrecoCreators.searchProductSuccess(data))
    } catch (e) {
        console.log(e)
    }
}


export function* pesquisaPrecoSaga(): any {
    yield all([
        takeEvery(Types.SEARCH_BEGIN, procurarProduto),
    ])
}
