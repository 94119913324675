import React from 'react';
import styled from "styled-components";
import {VendaCard} from "../../components/CardVenda";
import {connect} from "react-redux";
import {colorRed} from "../../styles/variables";

const CardErro = ({produtoError}: any) => {
    return produtoError ? (
        <Container>
            <ErroIcon />
            <ErrorMessage>{produtoError}</ErrorMessage>
        </Container>
    ) : null;
};

const mapStateToProps = (state: any) => {
    const {produtoError} = state.pedido

    return {
        produtoError,
    }
}

export default connect(mapStateToProps, null)(CardErro);


const Container = styled(VendaCard)`
position: absolute;
height: 100%;
width: 100%;
top: 0;
left: 0;
background-color: ${colorRed};
color: #FFF;
z-index: 10;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: 2rem;
padding: 4rem;    
`

const ErroIcon = styled.i.attrs(() => ({
    className: 'far fa-times-circle',
}))`
margin-bottom: 4rem;
font-size: 6rem;
`

const ErrorMessage = styled.div`
font-size: 3.5rem;
font-weight: bold;
text-align: center;
`
