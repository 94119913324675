import React, {useEffect, useRef} from 'react';
import {useTable} from 'react-table'
import styled from "styled-components";
import {Table, TBody, Td, THead, Tr} from "../../components/Table";
import DatePicker from 'react-datepicker'
import InputMonetario from "../../components/InputMonetario";
import useOutsideClick from "../../components/UseOutsideClick";
import {formataData} from "../../util/data_util";
import {formataValor} from "../../util/formatacao";

const EditableCurrencyCell = ({
                          value: initialValue,
                          row: {index},
                          column: {id},
                          updateMyData,
                      }) => {

    const [value, setValue] = React.useState(initialValue)
    const [isEditing, setIsEditing] = React.useState(false)

    const onChange = v => {
        setValue(v)
    }

    const onBlur = () => {
        setIsEditing(false)
        updateMyData(index, id, value)
    }

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const ref = useRef()

    useOutsideClick(ref, () => {
       if(isEditing) {
           onBlur()
       }
    })

    const openInput = () => {
        setIsEditing(true)
        // @ts-ignore
       setTimeout(() => ref?.current?.theInput?.focus(), 200)
    }

    return isEditing ? <InputMonetario value={value} onChange={onChange} onBlur={onBlur} ref={ref}/> :
        <div onClick={openInput}>{formataValor(value)}</div>
}

const EditableDateCell = ({
                          value: initialValue,
                          row: {index},
                          column: {id},
                          updateMyData,
                      }) => {

    const [value, setValue] = React.useState(initialValue)
    const [isEditing, setIsEditing] = React.useState(false)

    const onChange = v => {
        setValue(v)
    }

    const onBlur = () => {
        setIsEditing(false)
        updateMyData(index, id, value)
    }

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const ref = useRef()

    useOutsideClick(ref, () => {
        if(isEditing) {
            onBlur()
        }
    })

    const openInput = () => {
        setIsEditing(true)
        // @ts-ignore
        setTimeout(() => ref?.current?.input?.focus(), 200)
    }

    return isEditing ? <DatePicker selected={value} onChange={onChange} onBlur={onBlur} dateFormat={'dd/MM/yyyy'} ref={ref}/> :
        <div onClick={openInput}>{formataData(value)}</div>
}


const ParcelasTable = ({data = [], updateMyData}) => {


    const columns = React.useMemo(
        () => [
            {
                Header: 'Valor',
                accessor: 'valor',
                Cell: EditableCurrencyCell,
            },
            {
                Header: 'Vencimento',
                accessor: 'vencimento',
                Cell: EditableDateCell,
            },
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    } = useTable(
        {
            columns,
            data,
            updateMyData,
        },
    )

    return (
        <TableStyles {...getTableProps()}>
            <THead>
            {headerGroups.map(headerGroup => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                </Tr>
            ))}
            </THead>
            <TBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row)
                return (
                    <Tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <Td style={{width: '50%'}} {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                        })}
                    </Tr>
                )
            })}
            </TBody>
        </TableStyles>
    )
};

export default ParcelasTable;


const TableStyles = styled(Table)`
margin: 4rem;
`
