import React from 'react';
import styled from "styled-components";
import {Column, Row} from "../../styles/flex";
import {darkColor} from "../../styles/variables";
import {VendaCard} from "../../components/CardVenda";
import InputMonetario from "../../components/InputMonetario";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import Counter from "../../components/Counter";
import {PedidoItem} from "../../models/pedido_item.model";
import {calcTotalItemSelecionado, Creators as PedidoCreators} from '../../store/sagas/pedido'
import {toNumber} from "../../util/math_util";

interface ComponentTypes {
    item?: PedidoItem
    valorTotal: number
    aumentaQuantidade?: Function
    diminuiQuantidade?: Function
    alteraQuantidade?: Function
    handleDescontoPeloValorDesconto?: Function
    handleDescontoPeloValorAPagar?: Function
    concluiEdicao: Function
}

const CardAlteraItem = ({item, valorTotal, aumentaQuantidade, diminuiQuantidade, alteraQuantidade,
                          handleDescontoPeloValorDesconto, handleDescontoPeloValorAPagar, concluiEdicao}: ComponentTypes) => {
    return (
        <Card>
            <Header>
                <Title>ALTERAR ITEM</Title>
            </Header>
            <Body>
                <div className="product-detail">
                    <Label>QUANTIDADE</Label>
                    <Counter value={item?.quantidade} handlePlus={aumentaQuantidade} handleMinus={diminuiQuantidade} changeValue={alteraQuantidade} />
                </div>
                <InputContainer>
                    <Label>DESCONTO</Label>
                    <InputSubContainer>
                        <Cifrao>R$</Cifrao>
                        <InputValor value={item.valorDesconto} onChange={handleDescontoPeloValorDesconto} enterPressed={concluiEdicao}/>
                    </InputSubContainer>
                </InputContainer>
                <InputContainer>
                    <Label>A RECEBER</Label>
                    <InputSubContainer>
                        <Cifrao>R$</Cifrao>
                        <InputValor value={valorTotal} onChange={handleDescontoPeloValorAPagar} enterPressed={concluiEdicao}/>
                    </InputSubContainer>
                </InputContainer>

            </Body>
        </Card>
    );
};

const mapStateToProps = (state: State) => {
    return {
        item: state.pedido.pedidoItens[state.pedido.pedidoEmEdicaoIndex],
        valorTotal: calcTotalItemSelecionado(state, state.pedido.pedidoEmEdicaoIndex),
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    alteraQuantidade: (quantidade: number) => dispatch(PedidoCreators.editaItemAlteraQuantidade(quantidade)),
    aumentaQuantidade: () => dispatch(PedidoCreators.editaItemAumentaQuantidade()),
    diminuiQuantidade: () => dispatch(PedidoCreators.editaItemDiminuiQuantidade()),
    handleDescontoPeloValorAPagar: (valor: number) => dispatch(PedidoCreators.editItemDescontoPeloValorAPagar(toNumber(valor))),
    handleDescontoPeloValorDesconto: (valor: number) => dispatch(PedidoCreators.editItemDescontoPeloValorDesconto(toNumber(valor))),
    concluiEdicao: () => dispatch(PedidoCreators.handleAlteraItem(null)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardAlteraItem);


const Card = styled(VendaCard)`

`

const Header = styled.div`
${Row};
color: #FFF;
background-color: ${darkColor};
padding: .8rem 1.6rem;
`

const Title = styled.span`

`

const Body = styled.div`
${Column};
height: 100%;
padding-top: 4rem;
align-items: center;
`

const InputContainer = styled.div`
${Column};
justify-content: center;
overflow: hidden;
margin-left: 2rem;
margin-top: 4rem;
`

const Label = styled.span`
display: block;
font-size: 2rem;
margin-bottom: 1rem;
`

const InputSubContainer = styled.div`
display: grid;
grid-template-columns: 3rem 1fr;
align-items: end;
`

const Cifrao = styled.span`
color: rgb(254, 148, 46);
font-size: 1.8rem;
margin-bottom: 1rem;
`

const InputValor = styled(InputMonetario)`
border: none;
display: inline-block;
color: rgb(254, 148, 46);
font-size: 4rem;
width: 15rem;
`
