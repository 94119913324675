export class Formulario {
    static parseForm(obj: any, value: any, relacionamentos?: Array<{ chave: any, referencia: any }>, mascaras?: any, datas?: string[], checkbox?: string[], checkboxNovo?: string[], exclude?: any) : any {
        let data: any = {}
        if(exclude) exclude.forEach((v: any) => delete obj[v])

        Object.keys(value).forEach(key => {
            if (value[key] === undefined || value[key] === null) value[key] = ''
            typeof obj[key] === 'number' ? data[key] = Number.parseFloat(value[key].toString().replace(',', '.')) || 0 : data[key] = value[key].toString().includes('(') ? value[key] : value[key].toString() || ''
        })
        if (relacionamentos != null && relacionamentos.length > 0) {
            relacionamentos.forEach(v => {
                let nome = v.chave + '@xdata.ref';
                // eslint-disable-next-line eqeqeq
                if (data[v.chave] && data[v.chave].toString().match(/\d/g) && data[v.chave] != 0) {
                    if (data[v.chave].toString().includes('(')) {
                        data[nome] = data[v.chave]
                    } else {
                        data[nome] = v.referencia + '(' + data[v.chave] + ')'
                    }
                    delete data[v.chave];
                } else {
                    delete data[nome];
                    delete data[v.chave];
                }
            })
        }
        if (mascaras != null && mascaras.length > 0) {
            mascaras.forEach((value: any) => data[value] = this.removeMascara(data[value]))
        }
        if (datas != null && datas.length > 0) {
            datas.forEach(value => data[value] = this.dataParaString(data[value]))
        }
        if (checkbox != null && checkbox.length > 0) {
            checkbox.forEach(value => {
                data[value] = data[value] === true || data[value] === "true" ? 'S' : 'N'
            })

        }
        for (let key in data) {
            if (data[key] === "") data[key] = null
        }
        if (checkboxNovo != null && checkboxNovo.length > 0) {
            checkboxNovo.forEach(value => {
                data[value] = data[value] === 'TRUE' || data[value] === true || data[value] === 'true'
            })
        }
        if (data['Id'] === null || data['Id'] === '') data['Id'] = 0
        return data
    }

    static removeMascara(value: string) {
        if (value == null || value.trim() === '') return ''
        return value?.match(/\d/g)?.join('')
    }

    static dataParaString(data: any) {
        if (data === undefined || data === null || data === '') return ''
        if(typeof data === 'string') return  data
        let mes = (data.getUTCMonth() + 1).toString()
        if (mes.length === 1) mes = '0' + mes
        let dia = data.getUTCDate().toString()
        if (dia.length === 1) dia = '0' + dia
        return data.getUTCFullYear() + '-' + mes + '-' + dia
    }

}


