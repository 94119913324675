import React from 'react';
import {CardItem, CardPequeno, CardPequenoSecundary, PageContent, PageTitle, SecundaryCardItem, TextSubtitle} from "../../styles/commonStyles";
import {MeioPagamento} from "../../models/meio_pagamento.model";
import styled from "styled-components";

const SelecionaMeioPagamentoPage = ({meiosPagamentos, handlePagamento, getIconByDescription}) => {
    return (
        <PageContent>
            <PageTitle>PAGAMENTO</PageTitle>
            <Page>
                <TextSubtitle>Escolha a forma de pagamento</TextSubtitle>
                <MainContent>
                    {
                        meiosPagamentos.map((meioPagamento: MeioPagamento, index: number) => {
                            return index < 3 ? (
                                <CardItem key={index} onClick={()=> handlePagamento(meioPagamento)}>
                                    {index < 10 ? <span>{`F${index + 1}`}</span> : null}
                                    {getIconByDescription(meioPagamento.descricao)}
                                    <span className="item-menu-text" style={{textTransform: "uppercase"}}>{meioPagamento.descricao}</span>
                                </CardItem>
                            ) : (
                                <SecundaryCardItem key={index} onClick={()=> handlePagamento(meioPagamento)}>
                                    {index < 10 ? <span>{`F${index + 1}`}</span> : null}
                                    {getIconByDescription(meioPagamento.descricao)}
                                    <span className="item-menu-text" style={{textTransform: "uppercase"}}>{meioPagamento.descricao}</span>
                                </SecundaryCardItem>
                            )
                        })
                    }
                </MainContent>
                <div>
                    <CardPequeno>
                        <div className="descricao">
                            <span className="item-menu-text">F8</span>
                            <span className="item-menu-text">VER ITENS DA COMPRA</span>
                        </div>
                        <i className="fas fa-eye" />
                    </CardPequeno>
                    <CardPequenoSecundary className={'ajuste-icon-direita'}>
                        <div className="descricao">
                            <span className="item-menu-text">F8</span>
                            <span className="item-menu-text">CANCELAR COMPRA</span>
                        </div>
                        <i className="fas fa-times" />
                    </CardPequenoSecundary>
                </div>
            </Page>
        </PageContent>
    );
};

export default SelecionaMeioPagamentoPage;


const Page = styled.div`
   display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        grid-column-gap: 2rem;
        margin-top: 8rem;
`

const MainContent = styled.div`
     display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
`
