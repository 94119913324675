import {history} from './storeRoutes'
import {ConnectedRouter} from 'connected-react-router'
import {Switch, Route, Redirect} from 'react-router-dom'
import React from "react";
import InicialPage from "../pages/InicialPage";
import Login from "../pages/Login";
import VendaPage from "../pages/venda_page/VendaPage";
import ProtectedRoute from "./ProtectedRoute";


const RoutesConfig = () => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route path={'/login/:token'} component={Login} />
                <ProtectedRoute path={'/inicio'} component={InicialPage} />
                <ProtectedRoute path={'/venda'} component={VendaPage} />
                <Redirect path={''} exact to={'/inicio'} />
            </Switch>
        </ConnectedRouter>
    )
}

export default RoutesConfig

