import React from 'react';
import styled from "styled-components";
import NavMenu from "../layouts/NavMenu";
import {Switch, Route} from 'react-router-dom';
import Home from "./Home";
import PagamentoPage from "./pagamento/PagamentoPage";
import EscolhaCaixa from "./EscolhaCaixa";

const InicialPage: React.FC = (props: any) => {
    return (
        <MainMenuStyled>
            <NavMenu/>
            <Switch>
                <Route path='/inicio/escolha-caixa' component={EscolhaCaixa}/>
                <Route path='/inicio/pagamento' component={PagamentoPage}/>
                <Route path='/' component={Home}/>
            </Switch>
        </MainMenuStyled>
    );
};

const MainMenuStyled = styled.div`
display: grid;
grid-template-columns: 20rem 1fr;
grid-column-gap: 2rem;
height: 100vh;
`

export default InicialPage;
