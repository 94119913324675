import {Action} from "../../models/action.model";
import {all, call, put, takeEvery} from "redux-saga/effects";
import {PedidoItem} from "../../models/pedido_item.model";
import api from "../../config/network";
import {URL_BANCO_STORAGE_KEY} from "../../config/apiConstants";
import {Pessoa} from "../../models/pessoa.model";

export const Types = {
    CHANGE_MODAL_VISIBILITY: 'pesquisaPessoa/CHANGE_VISIBILITY',
    CLOSE_MODAL: 'pesquisaPessoa/CLOSE_MODAL',
    SEARCH_BEGIN: 'pesquisaPessoa/SEARCH_BEGIN',
    SEARCH_SUCCESS: 'pesquisaPessoa/SEARCH_SUCCESS',
}

export interface PesquisaPessoaTypes {
    modalAberto: boolean
    pessoas: Pessoa[]
    inputValue: string
}

const INITIAL_STATE: PesquisaPessoaTypes = {
    modalAberto: false,
    pessoas: [],
    inputValue: '',
}

export const PesquisaPessoaCreators = {
    changeModalVisible: () => ({type: Types.CHANGE_MODAL_VISIBILITY}),
    closeModal: () => ({type: Types.CLOSE_MODAL}),
    searchPessoaBegin: (value: any) => ({type: Types.SEARCH_BEGIN, payload: value}),
    searchPessoaSuccess: (value: PedidoItem) => ({type: Types.SEARCH_SUCCESS, payload: value}),
}

export const pesquisaPessoa = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case Types.CHANGE_MODAL_VISIBILITY:
            return {...state, modalAberto: !state.modalAberto}
        case Types.CLOSE_MODAL:
            return {...state, modalAberto: false, pessoas: []}
        case Types.SEARCH_SUCCESS:
            return {...state, pessoas: action.payload}
        default:
            return state
    }
}


function* procurarPessoa({payload}: Action) {
    try {
        let {data} = yield call(api.get, `/cad/${sessionStorage.getItem(URL_BANCO_STORAGE_KEY)}/pessoas/Essencials?Texto='${payload.texto}'&CampoOrdem=Nome&Pagina=0&Limite=1000`)
        data = data.value
        yield put(PesquisaPessoaCreators.searchPessoaSuccess(data))
    } catch (e) {
        console.log(e)
    }
}


export function* pesquisaPessoaSaga(): any {
    yield all([
        takeEvery(Types.SEARCH_BEGIN, procurarPessoa),
    ])
}
