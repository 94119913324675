import {all, call, put, select, takeEvery} from "redux-saga/effects";
import api from "../../config/network";
import {URL_BANCO_STORAGE_KEY} from "../../config/apiConstants";
import {Action} from "../../models/action.model";
import {PedidoItem} from "../../models/pedido_item.model";
import {mul6, sub, sum, toNumber} from "../../util/math_util";
import {createSelector} from 'reselect'
import {State} from "../reducers";
import {push} from "connected-react-router";
import {CondicaoPagamento} from "../../models/condicao_pagamento.model";
import {Formulario} from "../../util/Formulario";
import {OrcamentoPedidoVendaCab} from "../../models/orcamento_pedido_venda_cab.model";
import {OrcamentoPedidoVendaDet} from "../../models/orcamento_pedido_venda_det.model";
import {OpvcCondPagto} from "../../models/opvc_cond_pagto.model";
import {Produto} from "../../models/produto.model";
import {lowerFirstLetter, upperFirstLetter} from "../../util/transform_case";
import {MeioPagamento} from "../../models/meio_pagamento.model";
import {RedeAdquirente} from "../../models/rede_adquirente.model";
import {dataParaString} from "../../util/data_util";
import {gerarParcelas} from "../../util/parcelas_util";
import {cutArrayForExtractItem} from "../../util/array_util";
import {calcSubtotalItem, calcTotalItem} from "../../util/calc_util";
import {PesquisaPrecoCreators} from "./pesquisaPreco";
import {Pessoa} from "../../models/pessoa.model";

export const calcTotalItemSelecionado = createSelector(
// @ts-ignore
    (state: State, itemIndex: number) => [state.pedido.pedidoItens, itemIndex], ([items, itemIndex]) => calcTotalItem(items[itemIndex])
)

export const calcSubTotal = createSelector(
    (state: State) => state.pedido.pedidoItens,
    (items: PedidoItem[]) => items.map(pedidoItem => calcSubtotalItem(pedidoItem)).reduce((acc, curr) => sum(acc, curr), 0)
)

//faz a subtracao do desconto individual quando tiver
const calcTotalParcial = createSelector(
    [calcSubTotal, (state: State) => state.pedido.pedidoItens],
    (subTotal, items: PedidoItem[]) => sub(subTotal, items.map(pedidoItem => pedidoItem.valorDesconto).reduce((acc, curr) => sum(acc, curr), 0))
)

export const calcValorTotal = createSelector(
    [calcTotalParcial, (state: State) => state.pedido.desconto],
    (totalParcial, desconto,) => sub(totalParcial, desconto)
)

export const calcTotalRecebido = createSelector(
    (state: State) => state.pedido.pagamentos,
    (pagamentos: CondicaoPagamento[]) => pagamentos.map(pagamento => pagamento.valor).reduce((acc, curr) => sum(acc, curr), 0)
)

export const calcTotalAReceber = createSelector(
    [calcValorTotal, calcTotalRecebido],
    (totalPedido, totalRecebido) => sub(totalPedido, totalRecebido)
)

export const Types = {
    SEARCH_BEGIN: 'produto/SEARCH_BEGIN',
    ADICIONA_PRODUTO_PELA_PESQUISA_PRECO: 'produto/ADICIONA_PRODUTO_PELA_PESQUISA_PRECO',
    ADICIONA_PESSOA: 'produto/ADICIONA_PESSOA',
    SEARCH_SUCCESS: 'produto/SEARCH_SUCCESS',
    SEARCH_ERROR: 'produto/SEARCH_ERROR',
    ULTIMO_ITEM_AUMENTA_QUANTIDADE: 'ultimoItem/AUMENTA_QUANTIDADE',
    ULTIMO_ITEM_DIMINUI_QUANTIDADE: 'ultimoItem/DIMINUI_QUANTIDADE',
    EDITA_ITEM_AUMENTA_QUANTIDADE: 'editaItem/AUMENTA_QUANTIDADE',
    EDITA_ITEM_DIMINUI_QUANTIDADE: 'editaItem/DIMINUI_QUANTIDADE',
    ULTIMO_ITEM_ALTERA_QUANTIDADE: 'ultimoItem/ALTERA_QUANTIDADE',
    EDITA_ITEM_ALTERA_QUANTIDADE: 'editaItem/ALTERA_QUANTIDADE',
    ULTIMA_PARCELA_AUMENTA_QUANTIDADE: 'ultimaParcela/AUMENTA_QUANTIDADE',
    ULTIMA_PARCELA_DIMINUI_QUANTIDADE: 'ultimaParcela/DIMINUI_QUANTIDADE',
    ULTIMA_PARCELA_ALTERA_QUANTIDADE: 'ultimaParcela/ALTERA_QUANTIDADE',
    SELECIONA_PAGAMENTO: 'pagamento/SELECIONA_PAGAMENTO',
    SET_VALOR_CONDICAO_PAGAMENTO: 'condicaoPagamento/SET_VALUE',
    FINALIZAR_PEDIDO: 'pedido/FINALIZAR',
    FINALIZAR_PEDIDO_SUCCESS: 'pedido/FINALIZAR_SUCCESS',
    LIST_MEIO_PAGAMENTO: 'pagamento/LIST_MEIO_PAGAMENTO',
    LIST_MEIO_PAGAMENTO_SUCCESS: 'pagamento/LIST_MEIO_PAGAMENTO_SUCCESS',
    LIST_REDES_ADQUIRENTES: 'pagamento/LIST_REDES_ADQUIRENTES',
    LIST_REDES_ADQUIRENTES_SUCCESS: 'pagamento/LIST_REDES_ADQUIRENTES_SUCCESS',
    HANDLE_DESCONTO_PELO_PRECO: 'pedido/HANDLE_DESCONTO_PELO_PRECO',
    HANDLE_DESCONTO_PELO_VALOR_DESCONTO: 'pedido/HANDLE_DESCONTO_PELO_VALOR_DESCONTO',
    EDIT_ITEM_DESCONTO_PELO_PRECO: 'pedido/EDIT_ITEM_DESCONTO_PELO_PRECO',
    EDIT_ITEM_DESCONTO_PELO_VALOR_DESCONTO: 'pedido/EDIT_ITEM_DESCONTO_PELO_VALOR_DESCONTO',
    PUT_DESCONTO: 'pedido/PUT_DESCONTO',
    EDIT_ITEM_PUT_DESCONTO: 'pedido/EDIT_ITEM_PUT_DESCONTO',
    HANDLE_ALTERA_ITEM: 'pedido_item/HANDLE_ALTERA_ITEM',
    HANDLE_AUMENTA_QUANTIDADE_ITEM_PELO_INDEX: 'pedido_item/HANDLE_AUMENTA_QUANTIDADE_ITEM_PELO_INDEX',
    HANDLE_DIMINUI_QUANTIDADE_ITEM_PELO_INDEX: 'pedido_item/HANDLE_DIMINUI_QUANTIDADE_ITEM_PELO_INDEX',
    CLEAR_PEDIDO_STATE: 'pedido/CLEAR_PEDIDO_STATE',
}

export interface PedidoTypes {
    pedidoItens: PedidoItem[]
    pagamentos: CondicaoPagamento[]
    meiosPagamentos: MeioPagamento[]
    redesAdquirentes: RedeAdquirente[]
    desconto: number
    pedidoEmEdicaoIndex: number
    pessoa: Pessoa
}

const INITIAL_STATE = {
    pedidoItens: [],
    pagamentos: [],
    meiosPagamentos: [],
    redesAdquirentes: [],
    desconto: 0,
    pedidoEmEdicaoIndex: null,
    pessoa: null,
}

export const Creators = {
    searchProductBegin: (value: string) => ({type: Types.SEARCH_BEGIN, payload: value}),
    searchProductSuccess: (value: PedidoItem) => ({type: Types.SEARCH_SUCCESS, payload: value}),
    adicionaProdutoPelaPesquisaPreco: (value: Produto) => ({type: Types.ADICIONA_PRODUTO_PELA_PESQUISA_PRECO, payload: value}),
    adicionaPessoa: (value: Pessoa) => ({type: Types.ADICIONA_PESSOA, payload: value}),
    searchProductError: (value: string) => ({type: Types.SEARCH_ERROR, payload: value}),
    aumentaQuantidadeUltimoItem: () => ({type: Types.ULTIMO_ITEM_AUMENTA_QUANTIDADE}),
    diminuiQuantidadeUltimoItem: () => ({type: Types.ULTIMO_ITEM_DIMINUI_QUANTIDADE}),
    alteraQuantidadeUltimoItem: (quantidade: number) => ({type: Types.ULTIMO_ITEM_ALTERA_QUANTIDADE, payload: quantidade}),
    aumentaQuantidadeUltimoPagamento: () => ({type: Types.ULTIMA_PARCELA_AUMENTA_QUANTIDADE}),
    diminuiQuantidadeUltimoPagamento: () => ({type: Types.ULTIMA_PARCELA_DIMINUI_QUANTIDADE}),
    alteraQuantidadeUltimoPagamento: (quantidade: number) => ({type: Types.ULTIMA_PARCELA_ALTERA_QUANTIDADE, payload: quantidade}),
    selecionaPagamento: (condicao: CondicaoPagamento) => ({type: Types.SELECIONA_PAGAMENTO, payload: condicao}),
    setValorCondicaoPagamento: (valor: number) => ({type: Types.SET_VALOR_CONDICAO_PAGAMENTO, payload: valor}),
    finalizarPedido: () => ({type: Types.FINALIZAR_PEDIDO}),
    finalizarPedidoSuccess: () => ({type: Types.FINALIZAR_PEDIDO_SUCCESS}),
    listMeioPagamento: () => ({type: Types.LIST_MEIO_PAGAMENTO}),
    listMeioPagamentoSuccess: (value: MeioPagamento[]) => ({type: Types.LIST_MEIO_PAGAMENTO_SUCCESS, payload: value}),
    listRedesAdquirentes: () => ({type: Types.LIST_REDES_ADQUIRENTES}),
    listRedesAdquirentesSuccess: (value: RedeAdquirente[]) => ({type: Types.LIST_REDES_ADQUIRENTES_SUCCESS, payload: value}),
    handleDescontoPeloValorAPagar: (valor: number) => ({type: Types.HANDLE_DESCONTO_PELO_PRECO, payload: valor}),
    handleDescontoPeloValorDesconto: (valor: number) => ({type: Types.HANDLE_DESCONTO_PELO_VALOR_DESCONTO, payload: valor}),
    putDesconto: (valor: number) => ({type: Types.PUT_DESCONTO, payload: valor}),
    editItemPutDesconto: (valor: number) => ({type: Types.EDIT_ITEM_PUT_DESCONTO, payload: valor}),
    editaItemAumentaQuantidade: () => ({type: Types.EDITA_ITEM_AUMENTA_QUANTIDADE}),
    editaItemDiminuiQuantidade: () => ({type: Types.EDITA_ITEM_DIMINUI_QUANTIDADE}),
    editaItemAlteraQuantidade: (quantidade: number) => ({type: Types.EDITA_ITEM_ALTERA_QUANTIDADE, payload: quantidade}),
    editItemDescontoPeloValorAPagar: (valor: number) => ({type: Types.EDIT_ITEM_DESCONTO_PELO_PRECO, payload: valor}),
    editItemDescontoPeloValorDesconto: (valor: number) => ({type: Types.EDIT_ITEM_DESCONTO_PELO_VALOR_DESCONTO, payload: valor}),
    handleAlteraItem: (itemIndex: number) => ({type: Types.HANDLE_ALTERA_ITEM, payload: itemIndex}),
    handleAumentaQuantidadeItemIndex: (itemIndex: number) => ({type: Types.HANDLE_AUMENTA_QUANTIDADE_ITEM_PELO_INDEX, payload: itemIndex}),
    handleDiminuiQuantidadeItemIndex: (itemIndex: number) => ({type: Types.HANDLE_DIMINUI_QUANTIDADE_ITEM_PELO_INDEX, payload: itemIndex}),
    clearPedidoState: () => ({type: Types.CLEAR_PEDIDO_STATE}),
}

export const pedido = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case Types.SEARCH_SUCCESS:
            return {...state, produtoError: null, pedidoItens: [...state.pedidoItens, action.payload]}
        case Types.SEARCH_ERROR:
            return {...state, produtoError: action.payload}
        case Types.ULTIMO_ITEM_ALTERA_QUANTIDADE:
            if (!state.pedidoItens.length) return state

            const ultimoItem1: PedidoItem = state.pedidoItens.splice(state.pedidoItens.length - 1, 1)[0]
            ultimoItem1.quantidade = toNumber(action.payload)

            return {
                ...state,
                pedidoItens: [...state.pedidoItens, {...ultimoItem1}]
            }
        case Types.ULTIMO_ITEM_AUMENTA_QUANTIDADE:
            if (!state.pedidoItens.length) return state

            const ultimoItem: PedidoItem = state.pedidoItens.splice(state.pedidoItens.length - 1, 1)[0]
            ultimoItem.quantidade = sum(ultimoItem.quantidade, 1)

            return {
                ...state,
                pedidoItens: [...state.pedidoItens, {...ultimoItem}]
            }
        case Types.ULTIMO_ITEM_DIMINUI_QUANTIDADE:
            const qtdItens = state.pedidoItens.length
            // @ts-ignore
            if (!qtdItens || state.pedidoItens[qtdItens - 1].quantidade <= 1) return state

            const lastItem: PedidoItem = state.pedidoItens.splice(qtdItens - 1, 1)[0]

            lastItem.quantidade = sub(lastItem.quantidade, 1)

            return {
                ...state,
                pedidoItens: [...state.pedidoItens, {...lastItem}]
            }


        case Types.EDITA_ITEM_ALTERA_QUANTIDADE:
            let [firstPart, itemSelected, lastPart] = cutArrayForExtractItem(state.pedidoItens, state.pedidoEmEdicaoIndex)

            itemSelected = {
                ...itemSelected,
                quantidade: toNumber(action.payload),
            }

            return {
                ...state,
                pedidoItens: [...firstPart, itemSelected, ...lastPart]
            }
        case Types.EDITA_ITEM_AUMENTA_QUANTIDADE:
            let [firstPart1, itemSelected1, lastPart1] = cutArrayForExtractItem(state.pedidoItens, state.pedidoEmEdicaoIndex)
            const novaQuantidade = sum(itemSelected1.quantidade, 1)
            itemSelected1 = {...itemSelected1, quantidade: novaQuantidade}

            return {
                ...state,
                pedidoItens: [...firstPart1, itemSelected1, ...lastPart1]
            }
        case Types.EDITA_ITEM_DIMINUI_QUANTIDADE:
            const stateBackup = {...state}
            let [firstPart2, itemSelected2, lastPart2] = cutArrayForExtractItem(state.pedidoItens, state.pedidoEmEdicaoIndex)
            const novaQuantidade1 = sub(itemSelected2.quantidade, 1)

            if (novaQuantidade1 < 1) return stateBackup

            itemSelected2 = {...itemSelected2, quantidade: novaQuantidade1}

            return {
                ...state,
                pedidoItens: [...firstPart2, itemSelected2, ...lastPart2]
            }


        case Types.HANDLE_AUMENTA_QUANTIDADE_ITEM_PELO_INDEX:
            let [firstPart4, itemSelected4, lastPart4] = cutArrayForExtractItem(state.pedidoItens, action.payload)
            const novaQuantidade4 = sum(itemSelected4.quantidade, 1)
            itemSelected4 = {...itemSelected4, quantidade: novaQuantidade4}

            return {
                ...state,
                pedidoItens: [...firstPart4, itemSelected4, ...lastPart4]
            }
        case Types.HANDLE_DIMINUI_QUANTIDADE_ITEM_PELO_INDEX:
            const stateBackup2 = {...state}
            let [firstPart5, itemSelected5, lastPart5] = cutArrayForExtractItem(state.pedidoItens, action.payload)
            const novaQuantidade5 = sub(itemSelected5.quantidade, 1)

            if (novaQuantidade5 < 1) return stateBackup2

            itemSelected5 = {...itemSelected5, quantidade: novaQuantidade5}

            return {
                ...state,
                pedidoItens: [...firstPart5, itemSelected5, ...lastPart5]
            }


        case Types.SELECIONA_PAGAMENTO:
            return {...state, pagamentos: [...state.pagamentos, gerarParcelas(action.payload)]}
        case Types.SET_VALOR_CONDICAO_PAGAMENTO:
            const pag: CondicaoPagamento = state.pagamentos.splice(state.pagamentos.length - 1, 1)[0]
            const pagRecalculado = gerarParcelas(pag)

            return {...state, pagamentos: [...state.pagamentos, {...pagRecalculado, valor: action.payload}]}
        case Types.LIST_MEIO_PAGAMENTO_SUCCESS:
            return {...state, meiosPagamentos: action.payload}
        case Types.LIST_REDES_ADQUIRENTES_SUCCESS:
            return {...state, redesAdquirentes: action.payload}
        case Types.ULTIMA_PARCELA_ALTERA_QUANTIDADE:
            const ultimoPagamento1: CondicaoPagamento = state.pagamentos.splice(state.pagamentos.length - 1, 1)[0]
            ultimoPagamento1.quantidadeParcela = toNumber(action.payload)
            const result1 = gerarParcelas(ultimoPagamento1)
            return {
                ...state,
                pagamentos: [...state.pagamentos, result1]
            }
        case Types.ULTIMA_PARCELA_AUMENTA_QUANTIDADE:
            const ultimoPagamento: CondicaoPagamento = state.pagamentos.splice(state.pagamentos.length - 1, 1)[0]
            ultimoPagamento.quantidadeParcela = sum(ultimoPagamento.quantidadeParcela, 1)
            const result = gerarParcelas(ultimoPagamento)
            return {
                ...state,
                pagamentos: [...state.pagamentos, result]
            }
        case Types.ULTIMA_PARCELA_DIMINUI_QUANTIDADE:
            // @ts-ignore
            if (state.pagamentos[state.pagamentos.length - 1].quantidadeParcela <= 1) return state

            const ultimoPagament: CondicaoPagamento = state.pagamentos.splice(state.pagamentos.length - 1, 1)[0]
            ultimoPagament.quantidadeParcela = sub(ultimoPagament.quantidadeParcela, 1)
            const resul = gerarParcelas(ultimoPagament)
            return {
                ...state,
                pagamentos: [...state.pagamentos, resul]
            }
        case Types.PUT_DESCONTO:
            return {...state, desconto: action.payload}
        case Types.EDIT_ITEM_PUT_DESCONTO:
            let [firstPart3, itemSelected3, lastPart3] = cutArrayForExtractItem(state.pedidoItens, state.pedidoEmEdicaoIndex)
            const valorDesconto = toNumber(action.payload)
            itemSelected3 = {...itemSelected3, valorDesconto, valorTotal: toNumber(sub(itemSelected3.valorSubtotal, valorDesconto))}
            return {
                ...state,
                pedidoItens: [...firstPart3, itemSelected3, ...lastPart3]
            }
        case Types.HANDLE_ALTERA_ITEM:
            return {...state, pedidoEmEdicaoIndex: action.payload}
        case Types.ADICIONA_PESSOA:
            return {...state, pessoa: action.payload}
        case Types.CLEAR_PEDIDO_STATE:
            return {...state, ...INITIAL_STATE}
        default:
            return state
    }
}

function* procurarProduto({payload}: Action) {
    try {
        let {data} = yield call(api.get, `/cad/${sessionStorage.getItem(URL_BANCO_STORAGE_KEY)}/produtos/produtos?Texto='${payload.codigo}'&CampoOrdem=Nome&Pagina=0&Limite=1000`)
        data = data.value

        if (!data.length) throw new Error()

        if(data.length > 1) {
            yield put(PesquisaPrecoCreators.putProducts(data))
            return
        }

        const produto: Produto = data[0]

        const valorTotal = toNumber(mul6(produto.ValorVenda, payload.quantidade))
        const pedidoItem: PedidoItem = {
            quantidade: payload.quantidade,
            idProduto: produto,
            valorUnitario: produto.ValorVenda,
            valorTotal,
            valorSubtotal: valorTotal,
        }

        yield put(Creators.searchProductSuccess(pedidoItem))
    } catch (e) {
        yield put(Creators.searchProductError('PRODUTO NÃO ENCONTRADO'))
    }
}

function* adicionaProdutoPelaPesquisaPreco({payload}: any) {
    const produto: Produto = payload
    const valorTotal = produto.ValorVenda
    const pedidoItem: PedidoItem = {
        quantidade: 1,
        idProduto: produto,
        valorUnitario: produto.ValorVenda,
        valorTotal,
        valorSubtotal: valorTotal,
    }
    yield put(Creators.searchProductSuccess(pedidoItem))
    yield put(PesquisaPrecoCreators.closeModal())
}

function* pagamentoSelecionado({payload}: Action) {
    yield put(push('/venda'))
}

function* finalizarPedido() {
    try {
        let nextMonth = new Date()
        nextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, nextMonth.getDate())

        const {pedidoItens, pagamentos, movimentoUid} = yield select((state: State) => {
            return {
                pedidoItens: state.pedido.pedidoItens,
                pagamentos: state.pedido.pagamentos,
                movimentoUid: state.caixa?.movimento?.uid,
            }
        })

        const state = yield select()
        const total = yield calcValorTotal(state)
        const subtotal = yield calcSubTotal(state)
        const PedidoFormValues = {
            Tipo: 'P',
            StatusPedido: 'R',
            TotalGeral: total,
            ValorTotal: total,
            ValorSubtotal: subtotal,
            MovimentoUid: movimentoUid,
        }
        const pedido = {"$id": 1, ...Formulario.parseForm(new OrcamentoPedidoVendaCab(), {...PedidoFormValues})}
        const OrcamentoPedidoVendaDetList = pedidoItens.map((item: PedidoItem) => ({
            ...Formulario.parseForm(new OrcamentoPedidoVendaDet(), {
                Descricao: item.idProduto?.Nome, IdProduto: item.idProduto?.Id, Quantidade: item.quantidade, UnidEmbalagem: item.idProduto?.Unid,
                ValorTotal: item.valorTotal, ValorSubtotal: item.valorTotal, ValorUnitario: item.valorUnitario, ValorDesconto: item.valorDesconto,
            }), IdOrcamentoVendaCabecalho: {"$ref": 1},
        }))
        pedido.ValorDesconto = OrcamentoPedidoVendaDetList.map((v) => v.ValorDesconto).reduce((acc, curr) => sum(acc, curr), 0)
        pedido.OpvcCondPagtoList = pagamentos.map((pagamento: CondicaoPagamento) => ({
            "$id": 2,
            ...Formulario.parseForm(new OpvcCondPagto(), upperFirstLetter(pagamento)),
            IdOpvc: {"$ref": 1},
            OpvcCondPagtoParcelaList: [
                {
                    IdOpvcCondPagto: {$ref: 2},
                    Valor: toNumber(pagamento.valor),
                    Vencimento: dataParaString(nextMonth)
                },
            ],
        }))

        yield call(api.post, `/nfce/${sessionStorage.getItem(URL_BANCO_STORAGE_KEY)}/nfce/gravarPedido`, pedido)

        yield put(Creators.finalizarPedidoSuccess())

    } catch (e) {
        console.log(e)
    }

}

function* pedidoConcluido() {
    yield put(Creators.clearPedidoState())
    yield put(push('/venda'))
}


function* listarMeiosPagamentos() {
    let {data} = yield call(api.get, `/nfce/${sessionStorage.getItem(URL_BANCO_STORAGE_KEY)}/nfce/listameiospagamentopdv`)
    data = data.value

    yield put(Creators.listMeioPagamentoSuccess(lowerFirstLetter(data)))
}


function* listarRedesAdquirentes() {
    let {data} = yield call(api.get, `/nfce/${sessionStorage.getItem(URL_BANCO_STORAGE_KEY)}/nfce/listaredesadquirentes`)
    data = data.value

    yield put(Creators.listRedesAdquirentesSuccess(lowerFirstLetter(data)))
}

function* descontoPeloValorAPagar({payload: novoValorTotal}: Action) {
    const subtotal = yield select((state: State) => calcSubTotal(state))
    const valorDesconto = sub(subtotal, novoValorTotal)
    yield put(Creators.putDesconto(valorDesconto))
}

function* descontoPeloValorDesconto({payload: valorDesconto}: Action) {
    yield put(Creators.putDesconto(valorDesconto))
}

function* editItemDescontoPeloValorAPagar({payload: novoValorTotal}: Action) {
    const subtotal = yield select((state: State) => state.pedido.pedidoItens[state.pedido.pedidoEmEdicaoIndex].valorSubtotal)
    const valorDesconto = sub(subtotal, novoValorTotal)
    yield put(Creators.editItemPutDesconto(valorDesconto))
}

function* editItemDescontoPeloValorDesconto({payload: valorDesconto}: Action) {
    yield put(Creators.editItemPutDesconto(valorDesconto))
}

export function* pedidoSaga(): any {
    yield all([
        takeEvery(Types.SEARCH_BEGIN, procurarProduto),
        takeEvery(Types.ADICIONA_PRODUTO_PELA_PESQUISA_PRECO, adicionaProdutoPelaPesquisaPreco),
        takeEvery(Types.SELECIONA_PAGAMENTO, pagamentoSelecionado),
        takeEvery(Types.FINALIZAR_PEDIDO, finalizarPedido),
        takeEvery(Types.LIST_MEIO_PAGAMENTO, listarMeiosPagamentos),
        takeEvery(Types.LIST_REDES_ADQUIRENTES, listarRedesAdquirentes),
        takeEvery(Types.FINALIZAR_PEDIDO_SUCCESS, pedidoConcluido),
        takeEvery(Types.HANDLE_DESCONTO_PELO_PRECO, descontoPeloValorAPagar),
        takeEvery(Types.HANDLE_DESCONTO_PELO_VALOR_DESCONTO, descontoPeloValorDesconto),
        takeEvery(Types.EDIT_ITEM_DESCONTO_PELO_PRECO, editItemDescontoPeloValorAPagar),
        takeEvery(Types.EDIT_ITEM_DESCONTO_PELO_VALOR_DESCONTO, editItemDescontoPeloValorDesconto),
    ])
}
