import React, {useState} from 'react';
import Topbar from "../../layouts/Topbar";
import styled from "styled-components";
import CardAdicionarProduto from "./CardAdicionarProduto";
import CardProdutosAdicionados from "./CardProdutosAdicionados";
import {PrimaryButton, SecundaryButton} from "../../components/Button";
import {darkColor} from "../../styles/variables";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {calcTotalRecebido, calcValorTotal, Creators as PedidoCreators} from '../../store/sagas/pedido'
import CardErro from "./CardErro";
import CardDetalhePagamento from "../pagamento/CardDetalhePagamento";
import CardValorPago from "../pagamento/CardValorPago";
import {CondicaoPagamento} from "../../models/condicao_pagamento.model";
import {CondicaoPagamentoParcela} from "../../models/condicao_pagamento_parcela.model";
import {sum6, toNumber} from "../../util/math_util";
import CardAlterarItem from "./CardAlterarItem";
import CardDesconto from "./CardDesconto";
import {State} from "../../store/reducers";
import Funcoes from "../../layouts/Funcoes";
import {InterfacesCreatoes} from "../../store/sagas/interfaces";

interface PropTypes {
    pagamentos: CondicaoPagamento[]
    ultimoPagamento: CondicaoPagamento
    totalPedido: number
    totalRecebido: number
    searchProduct: Function
    history: any
    finalizaPedido: Function
    isItemEmEdicao: boolean
    isDescontoGlobalOpened: boolean
    changeVisibilityDescontoGlobal: Function
}

const VendaPage: any = ({
                            searchProduct,
                            history,
                            pagamentos,
                            totalAPagar,
                            totalRecebido,
                            finalizaPedido,
                            ultimoPagamento,
                            isItemEmEdicao,
                            isDescontoGlobalOpened,
                            toogleFuncoesVisibility,
                        }: any) => {

    const [inputValue, setInputValue] = useState('')

    const parcelasNaoFechamComTotal = () => {
        const total = toNumber(ultimoPagamento.valor)
        const totaisParcelas = toNumber(ultimoPagamento.opvcCondPagtoParcelaList.map((parcela: CondicaoPagamentoParcela) => parcela.valor)
            .reduce((acc, cur) => sum6(acc, cur), 0))

        return total !== totaisParcelas
    }

    const encerraVenda = () => {
        if (totalAPagar !== totalRecebido) {
            history.push('/inicio/pagamento')
        } else if (parcelasNaoFechamComTotal()) {

        } else {
            finalizaPedido()
        }
    }

    const changeInputValue = (v: string) => {
        setInputValue(v)
    }

    const handleSearch = (e: any) => {
        if (e.type !== 'blur' && !(e.type === 'keypress' && e.key === 'Enter')) return

        const quantidadeRegex = /^\d+\*|^\d+\s+\*|^\d+\s+\*\s+/
        let quantidade: any = 1
        if (inputValue.match(quantidadeRegex)) {
            // @ts-ignore
            quantidade = inputValue.match(/^\d+/)[0]
        }

        const codigo = inputValue.replace(quantidadeRegex, '')

        if (!codigo.length) return;

        searchProduct({quantidade, codigo})
        setInputValue('')
    }


    return (
        <VendaPageContainer>
            <Topbar/>
            {/*<MainContent>*/}
            {/*<CardValorPago />*/}
            {/*<CardDesconto />*/}
            {/*    <CardAlterarItem />*/}
            {/*    <CardDetalhePagamento />*/}
            {/*</MainContent>*/}

            {
                !pagamentos.length ?
                    <MainContent>
                        <LeftContainer>
                            {
                                isItemEmEdicao ? <CardAlterarItem/> : isDescontoGlobalOpened ? <CardDesconto/> :
                                    <>
                                        <CardErro/>
                                        <CardAdicionarProduto/>
                                    </>
                            }
                        </LeftContainer>
                        <CardProdutosAdicionados/>
                    </MainContent>
                    :
                    <MainContent>
                        <CardValorPago/>
                        <CardDetalhePagamento/>
                    </MainContent>
            }
            <FooterPage>
                <Botoes>
                    <SecundaryButton onClick={encerraVenda}>
                        <i className={"fas fa-file-invoice-dollar"}/> <span>Encerra Venda</span>
                    </SecundaryButton>
                    <PrimaryButtonStyled onClick={toogleFuncoesVisibility}>
                        <i className={"fas fa-cogs"}/> Funções
                    </PrimaryButtonStyled>
                </Botoes>
                <InputContainer>
                    <label>Codigo</label>
                    <input placeholder={'Didite a quantidade * o codigo do produto desejado'}
                           value={inputValue} onChange={(e) => changeInputValue(e.target.value)}
                           onBlur={handleSearch} onKeyPress={handleSearch}/>
                </InputContainer>
            </FooterPage>
            <Funcoes/>
        </VendaPageContainer>
    );
};

VendaPage.routeName = '/venda'

const mapStateToProps = (state: State) => {

    const pagamentos = state.pedido.pagamentos

    return {
        pagamentos,
        totalAPagar: calcValorTotal(state),
        totalRecebido: calcTotalRecebido(state),
        ultimoPagamento: pagamentos.length ? pagamentos[pagamentos.length - 1] : {valor: 0, opvcCondPagtoParcelaList: []},
        isItemEmEdicao: state.pedido.pedidoEmEdicaoIndex !== null,
        isDescontoGlobalOpened: state.interfaces.descontoGlobalOpened,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    searchProduct: (value: string) => dispatch(PedidoCreators.searchProductBegin(value)),
    finalizaPedido: () => dispatch(PedidoCreators.finalizarPedido()),
    toogleFuncoesVisibility: () => dispatch(InterfacesCreatoes.toogleFuncoesVisibility()),
})

export default connect(mapStateToProps, mapDispatchToProps)(VendaPage);


const VendaPageContainer = styled.div`
display: flex;
flex-direction: column;
max-height: 100%;
overflow-x: hidden;
`

const MainContent = styled.div`
    display: grid;
    grid-column-gap: 3rem;
    margin: 3rem 3rem 2rem;
    grid-template-columns: 1.5fr 2fr;
    flex: 1;
    height: 30rem!important;
    overflow: hidden;
`

const LeftContainer = styled.div`
position: relative;
`

const FooterPage = styled(MainContent)`
margin-top: 0;
min-height: 0;
max-height: 10rem;
`

const Botoes = styled.div`
margin-top: 1.6rem;
`

const PrimaryButtonStyled = styled(PrimaryButton)`
margin-left: 2rem;
`

const InputContainer = styled.div`
label {
display: block;
margin-bottom: .2rem;
}

input { 
   width: 100%;
    padding: .8rem;
    border-radius: .3rem;
    border: .1rem solid ${darkColor};
}
`
