import {useEffect} from 'react';

const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
        if(ref.current) {
            if(ref.current.theInput && !ref.current.theInput.contains(e.target)) {
                callback()
                return
            } else if(ref.current.input && !ref.current.input.contains(e.target)) {
                callback()
                return;
            } else if(ref.current.contains && !ref.current.contains(e.target)) {
                callback()
                return;
            }
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClick)

        return () => {
            document.removeEventListener('click', handleClick)
        }
    })

};

export default useOutsideClick;
