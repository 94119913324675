import {CondicaoPagamento} from "../models/condicao_pagamento.model";
import {CondicaoPagamentoParcela} from "../models/condicao_pagamento_parcela.model";
import {div, sub, sum} from "./math_util";

export const gerarParcelas = (pagamento: CondicaoPagamento) => {
    try {
        let currentDate = new Date()

        switch (pagamento.descricao) {
            case 'Cartao Debito POS':
                currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1)
                break;
            case 'Cartao Credito POS':
                currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 30)
                break;
            case 'Alimentacao':
            case 'Refeicao':
            case 'Boleto Crediario':
            case 'Cheque':
            case 'Deposito - Outros':
                currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 29)
                break;
            case 'Vale Presente':
            case 'Dinheiro':
            case 'Credito na Loja':
                break;
        }

        const valorPorParcela = div(pagamento.valor, pagamento.quantidadeParcela);

        const parcelas: CondicaoPagamentoParcela[] = [{
            valor: valorPorParcela,
            vencimento: copyDate(currentDate),
        }]

        if (pagamento.quantidadeParcela && pagamento.quantidadeParcela > 1) {
            // @ts-ignore
            for (let i = 1; i < pagamento.quantidadeParcela; i++) {
                currentDate.setDate(currentDate.getDate() + 30)
                parcelas.push({
                    valor: valorPorParcela,
                    vencimento: copyDate(currentDate),
                })
            }
        }

        const valorParcelas = parcelas.reduce((acc, cur) => sum(acc, cur.valor), 0)
        const diferenca = sub(pagamento.valor, valorParcelas)
        parcelas[0] = {...parcelas[0], valor: sum(parcelas[0].valor, diferenca)}

        pagamento.opvcCondPagtoParcelaList = parcelas

        return pagamento

    } catch (e) {
        console.log(e)
    }
}


export const copyDate = (data: Date) => {
    return new Date(data.getFullYear(), data.getMonth(), data.getDate())
}
