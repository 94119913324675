import React, {useEffect, useState} from 'react';
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {calcTotalAReceber, Creators as PedidoCreators} from "../../store/sagas/pedido";
import {CondicaoPagamento} from "../../models/condicao_pagamento.model";
import {State} from "../../store/reducers";
import {MeioPagamento} from "../../models/meio_pagamento.model";
import SelecionaMeioPagamentoPage from "./SelecionaMeioPagamentoPage";
import SelecionaListaAdquirentePage from "./SelecionaListaAdquirentePage";
import {RedeAdquirente} from "../../models/rede_adquirente.model";

const PagamentoPage = ({selecionaPagamento, listMeiosPagamento, listRedesAdquirentes, meiosPagamentos, redesAdquirentes, totalAReceber}: any) => {

    const [pagamentoAtual, setPagamentoAtual] = useState<CondicaoPagamento>()

    useEffect(() => {
        listMeiosPagamento()
        listRedesAdquirentes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePagamento = (meioPagamento: MeioPagamento) => {

        const condicao: CondicaoPagamento = {
            descricao: meioPagamento.descricao,
            codigoTipoPagNfe: meioPagamento.codigo,
            quantidadeParcela: 1,
            valor: totalAReceber,
        }

        if(meioPagamento.codigo === "03" || meioPagamento.codigo === "04") {
            if(!meioPagamento.pos) return

            condicao.codigoBandeira = '99'
            condicao.rede = 'Outros'
            condicao.cartaoDc = meioPagamento.codigo === '03' ? 'D' : 'C'

            setPagamentoAtual(condicao)

            return;
        }

        selecionaPagamento(condicao)
    }

    const handleAdquirente = (redeAdquirente: RedeAdquirente) => {
        selecionaPagamento({...pagamentoAtual, cnpjOperadora: redeAdquirente.cnpj})
    }

    const getIconByDescription = (descricao: string) => {
        switch (descricao) {
            case 'Cartao Debito POS':
                return <i className="far fa-credit-card ajust-icon-esquerda" />
            case 'Cartao Credito POS':
                return <i className="far fa-credit-card ajust-icon-esquerda" />
            case 'Dinheiro':
                return <i className="far fa-money-bill-alt ajust-icon-esquerda" />
            case 'Cheque':
                return  <i className="fas fa-money-check-alt ajust-icon-esquerda" />
            case 'Credito na Loja':
                return <i className="fas fa-dollar-sign" />
            case 'Alimentacao':
                return <i className="fas fa-shopping-basket" />
            case 'Refeicao':
                return <i className="fas fa-utensils" />
            case 'Vale Presente':
                return <i className="fas fa-gift" />
            case 'Boleto Crediario':
                return <i className="fas fa-file-invoice-dollar ajust-icon-direita" />
            case 'Deposito - Outros':
                return <i className="fas fa-receipt ajust-icon-direita" />
            default:
                return <i className="fas fa-dollar-sign ajust-icon-direita" />
        }
    }

    return !pagamentoAtual ? (
        <SelecionaMeioPagamentoPage
            getIconByDescription={getIconByDescription}
            handlePagamento={handlePagamento}
            meiosPagamentos={meiosPagamentos} />
    ) : (
        <SelecionaListaAdquirentePage
            redesAdquirentes={redesAdquirentes}
            handleAdquirente={handleAdquirente}/>
    )
};

const mapStateToProps = (state: State) => {
    return {
        meiosPagamentos: state.pedido.meiosPagamentos,
        redesAdquirentes: state.pedido.redesAdquirentes,
        totalAReceber: calcTotalAReceber(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    selecionaPagamento: (condicao: CondicaoPagamento) => dispatch(PedidoCreators.selecionaPagamento(condicao)),
    listMeiosPagamento: () => dispatch(PedidoCreators.listMeioPagamento()),
    listRedesAdquirentes: () => dispatch(PedidoCreators.listRedesAdquirentes()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PagamentoPage);


