import React, {useState} from 'react';
import BaseModal from "./BaseModal";
import {State} from "../store/reducers";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {PesquisaPessoaCreators} from "../store/sagas/pesquisaPessoa";
import {Creators as PedidoCreators} from "../store/sagas/pedido";
import Input from "../components/Input";
import styled from "styled-components";
import {Pessoa} from "../models/pessoa.model";

interface ComponentTypes {
    isVisible: boolean
    closeModal: Function
    pessoas: Pessoa[]
    handleSearch: Function
    setPessoa: Function
}

const PesquisarPessoaModal: any = ({isVisible, closeModal, pessoas, handleSearch, setPessoa}: ComponentTypes) => {

    const [selectedItem, setSelectedItem] = useState<number>(0)
    const [inputValue, setInputValue] = useState('')

    const confirm = () => {
        setPessoa(pessoas[selectedItem])
    }

    return (
        <BaseModal width={'60%'} isVisible={isVisible} closeModal={closeModal} hiddenConfirm={confirm}>
            <Input normal placeholder={'Didite a pessoa a ser pesquisada'} value={inputValue} onChange={(e) => {
                setInputValue(e.target.value)
            }}
                   onBlur={() => handleSearch(inputValue)} onKeyDown={e => {
                if (e.key === 'Enter') {
                    handleSearch(inputValue)
                }
            }}/>
            <ItemsContainer>
                {
                    pessoas.map((pessoa, index) => {
                        return (
                            <Item key={index} isSelected={selectedItem === index} onMouseEnter={() => setSelectedItem(index)}>
                                <Img src={null}/>
                                <Nome>{pessoa.Nome}</Nome>
                                <Info>{pessoa.CpfCnpj}</Info>
                            </Item>
                        )
                    })
                }
            </ItemsContainer>
        </BaseModal>
    );
};


const mapStateToProps = (state: State) => {
    return {
        isVisible: state.pesquisaPessoa.modalAberto,
        pessoas: state.pesquisaPessoa.pessoas,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(PesquisaPessoaCreators.changeModalVisible()),
    handleSearch: (v) => dispatch(PesquisaPessoaCreators.searchPessoaBegin({texto: v})),
    setPessoa: (pessoa: Pessoa) => dispatch(PedidoCreators.adicionaPessoa(pessoa))
})

export default connect(mapStateToProps, mapDispatchToProps)(PesquisarPessoaModal);

const ItemsContainer = styled.div`
width: 100%;
max-height: 45rem;
overflow-x: hidden;
overflow-y: auto;
margin-top: 2rem;
`

const Item: any = styled.div`
display: grid;
grid-template-columns: 5rem 1fr 8rem;
grid-column-gap: 2rem;
padding: 0 2rem;
align-items: center;
height: 8rem;
position: relative;

${({isSelected}: any) => isSelected ? ({
    backgroundColor: 'rgba(241,241,241,0.66)',
}) : null};

&::after {
content: '';
position: absolute;
left: 50%;
transform: translateX(-50%);
bottom: 0;
width: 100%;
height: .1rem;
background: linear-gradient(to right, transparent, rgba(215,212,212,0.7), transparent);
}

`

const Nome = styled.div`

`

const Info = styled.div`
text-align: right;
`

const Img = styled.img`
width: 5rem;
`
