import axios from "axios";
import {TOKEN_STORAGE_KEY} from "./apiConstants";

const api = axios.create({
    baseURL: 'https://api.toqweb.com.br:2004'
})

api.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem(TOKEN_STORAGE_KEY);
    config.headers.Authorization =  `Bearer ${token}`;

    return config;
});

export default api