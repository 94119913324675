import styled from "styled-components";
import {colorRed, primaryColor, secundaryColor} from "../styles/variables";

const Button = styled.button`
 border: none;
    outline: none;
    background-color: transparent;
    padding: .8rem 1.4rem;
    border-radius: .4rem;
    cursor: pointer;
    i {
        margin-right: .5rem;
    }
`

export const PrimaryButton = styled(Button)`
    color: ${primaryColor};
    border: .2rem solid ${primaryColor};
    transition: all .3s;

    &:hover {
        background-color: ${primaryColor};
        color: #FFF;
        transition: all .3s;
    }
`

export const SecundaryButton = styled(Button)`
    color: ${secundaryColor};
    border: .2rem solid ${secundaryColor};
    transition: all .3s;

    &:hover {
        background-color: ${secundaryColor};
        color: #FFF;
        transition: all .3s;
    }
`

export const RedButton = styled(Button)`
    color: ${colorRed};
    border: .2rem solid ${colorRed};
    transition: all .3s;

    &:hover {
        background-color: ${colorRed};
        color: #FFF;
        transition: all .3s;
    }
`
