import {Action} from "../../models/action.model";

export const Types = {
    TOOGLE_DESCONTO_GLOBAL: 'interface/TOOGLE_DESCONTO_GLOBAL',
    TOOGLE_FUNCOES_VISIBILITY: 'interface/TOOGLE_FUNCOES_VISIBILITY',
}

export const InterfacesCreatoes = {
    changeVisibilityDescontoGlobal: () => ({type: Types.TOOGLE_DESCONTO_GLOBAL}),
    toogleFuncoesVisibility: () => ({type: Types.TOOGLE_FUNCOES_VISIBILITY}),
}

export interface InterfacesTypes {
    descontoGlobalOpened: boolean
    funcoesVisible: boolean
}

const INITIAL_STATE: InterfacesTypes = {
    descontoGlobalOpened: false,
    funcoesVisible: false,
}

export const interfaces = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case Types.TOOGLE_DESCONTO_GLOBAL:
            return {...state, descontoGlobalOpened: !state.descontoGlobalOpened}
        case Types.TOOGLE_FUNCOES_VISIBILITY:
            return {...state, funcoesVisible: !state.funcoesVisible}
        default:
            return state
    }
}
