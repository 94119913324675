import React, {useEffect} from 'react';
import styled from "styled-components";
import {primaryColor, primaryColorLight} from "../styles/variables";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {Creators as CaixaCreators} from '../store/sagas/caixa'

const EscolhaCaixa = ({caixas, listCaixas, verificarMovimentacao}: any) => {

    useEffect(() => {
        listCaixas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleCaixa = (caixa: any) => {
        verificarMovimentacao(caixa.Id)
    }

    return (
        <CaixasPage>
            <Title>CAIXAS PDV</Title>

            <Caixas>
                {
                    caixas.map((caixa: any) => <Caixa key={caixa.Id} onClick={() => handleCaixa(caixa)}>
                        <>
                            <Icon className="fas fa-cash-register"/>
                            <CaixaName>{caixa.Descricao}</CaixaName>
                        </>
                    </Caixa>)
                }

            </Caixas>
        </CaixasPage>
    );
};

EscolhaCaixa.routeName = '/inicio/escolha-caixa'

const mapStateToProps = (state: any) => {
    const {caixas} = state.caixa

    return {
        caixas,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    listCaixas: () => dispatch(CaixaCreators.listCaixas()),
    verificarMovimentacao: (caixaId: number) => dispatch(CaixaCreators.verificarMovimento(caixaId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EscolhaCaixa);

const CaixasPage = styled.div`
padding: 2rem 8rem;
`

const Title = styled.h1`
font-size: 3.2rem;
color: ${primaryColor};
font-weight: bold;
margin-bottom: 2rem;
`

const Caixas = styled.div`
display: grid;
grid-template-columns: repeat(4, 1fr);
grid-gap: 2rem;
`

const Caixa = styled.div`
padding: 2rem;
background-color: ${primaryColor};
color: #FFF;
font-weight: bold;
user-select: none;
cursor: pointer;
box-shadow: 0 0 .5rem -.1rem rgba(0, 0, 0, 0.5);
transition: all .3s;

&:hover {
transition: all .3s;
background-color: ${primaryColorLight};
}
`

const Icon = styled.i`
font-size: 3rem;
margin-right: 1rem;
`

const CaixaName = styled.span`
font-size: 1.8rem;
`
