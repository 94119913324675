import React from 'react';
import ReactDOM from 'react-dom'
import NovaSangriaModal from "./NovaSangriaModal";
import PesquisarProdutoModal from "./PesquisarProdutoModal";
import PesquisarPessoaModal from "./PesquisarPessoaModal";

const ModalRoot = () => ReactDOM.createPortal(
    <>
        <NovaSangriaModal/>
        <PesquisarProdutoModal />
        <PesquisarPessoaModal />
    </>,
// @ts-ignore
    document.getElementById('modal-root')
)

export default ModalRoot;
