import React from 'react';
import {VendaCard} from "../../components/CardVenda";
import styled from "styled-components";
import {Column, Row} from "../../styles/flex";
import {darkColor} from "../../styles/variables";
import {connect} from "react-redux";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import {calcValorTotal, Creators as PedidoCreators} from "../../store/sagas/pedido";
import {toNumber} from "../../util/math_util";
import Counter from "../../components/Counter";
import {CondicaoPagamento} from "../../models/condicao_pagamento.model";
import ParcelasTable from "./ParcelasTable";
import InputMonetario from "../../components/InputMonetario";

interface PropTypes {
    ultimoPagamento: CondicaoPagamento
    setValorPago: Function
    adicionaParcela: Function
    diminuiParcela: Function
    alteraParcela: Function
}

const CardValorPago = ({ultimoPagamento, setValorPago, adicionaParcela, diminuiParcela, alteraParcela}: PropTypes) => {

    const updateMyData = (rowIndex, columnId, value) => {

        // const parcelas = ultimoPagamento.opvcCondPagtoParcelaList
        //TODO criar action para alterar as parcelas
        //     const parcelasALteradas = parcelas.map((row, index) => {
        //         if (index === rowIndex) {
        //             return {
        //                 ...parcelas[rowIndex],
        //                 [columnId]: value,
        //             }
        //         }
        //         return row
        //     })

    }

    return (
        <Card>
            <Header>
                <Title>INFORME O VALOR A PAGAR</Title>
            </Header>
            <Body>
                <Inputs>
                    <InputContainer>
                        <Label>VALOR PAGO</Label>
                        <InputSubContainer>
                            <Cifrao>R$</Cifrao>
                            <InputValorPago value={ultimoPagamento?.valor} onChange={(v: any) => setValorPago(toNumber(v))}/>
                        </InputSubContainer>
                    </InputContainer>
                    <InputContainer>
                        <Label>PARCELAS</Label>
                        <Counter value={ultimoPagamento?.quantidadeParcela} handlePlus={adicionaParcela} handleMinus={diminuiParcela} changeValue={alteraParcela} type={'inteiro'}/>
                    </InputContainer>
                    <InputContainer>
                        <Label>NR AUTORIZACAO</Label>
                        <InputAutorizacao/>
                    </InputContainer>
                </Inputs>

                <ParcelasTable
                    data={ultimoPagamento?.opvcCondPagtoParcelaList}
                    updateMyData={updateMyData}
                />

            </Body>
        </Card>
    );
};


const mapStateToProps = (state: State) => {
    const pagamentos = state.pedido.pagamentos

    return {
        pagamentos,
        totalPedido: calcValorTotal(state),
        ultimoPagamento: pagamentos.length ? pagamentos[pagamentos.length - 1] : {valor: 0, opvcCondPagtoParcelaList: []}
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setValorPago: (valor: number) => dispatch(PedidoCreators.setValorCondicaoPagamento(valor)),
    adicionaParcela: () => dispatch(PedidoCreators.aumentaQuantidadeUltimoPagamento()),
    diminuiParcela: () => dispatch(PedidoCreators.diminuiQuantidadeUltimoPagamento()),
    alteraParcela: (quantidade: number) => dispatch(PedidoCreators.alteraQuantidadeUltimoPagamento(quantidade)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardValorPago);


const Card = styled(VendaCard)`

`

const Header = styled.div`
${Row};
color: #FFF;
background-color: ${darkColor};
padding: .8rem 1.6rem;
`

const Title = styled.span`

`

const Body = styled.div`
${Column};
height: 100%;
padding-top: 4rem;
`

const Inputs = styled.div`
display: grid;
//ao colocar tamanho em porcentagem/fracao ele nao respeita e fica com tamanhos fixos grandes
grid-template-columns: 1fr 1fr 1.5fr;
grid-gap: 2rem;
overflow: hidden;
max-width: 100%;
padding: 0 4rem;
`

const InputContainer = styled.div`
${Column};
overflow: hidden;
`

const Label = styled.span`
display: block;
font-size: 2rem;
margin-bottom: 1rem;
`

const InputSubContainer = styled.div`
display: grid;
grid-template-columns: 3rem 1fr;
align-items: end;
`

const Cifrao = styled.span`
color: rgb(254, 148, 46);
font-size: 1.8rem;
margin-bottom: 1rem;
`

const InputValorPago = styled(InputMonetario)`
border: none;
display: inline-block;
color: rgb(254, 148, 46);
font-size: 4rem;
`

const InputAutorizacao = styled.input`
border: none;
border-bottom: .2rem solid rgb(254, 148, 46);
color: rgb(254, 148, 46);
font-size: 4rem;
`


