export const toNumber = (val: any, casas = 2): number =>  {
    const v = val === undefined ? 0 : val === null ? 0 : typeof val === 'number' ? Number(val.toFixed(casas)) : Number(Number.parseFloat(val.toString().replace(',', '.')).toFixed(casas))
    return isNaN(v) || !isFinite(v) ? 0 : v
}

export const sum = (a: any, b: any) => {
    a = toNumber(a)
    b = toNumber(b)
    return toNumber((a + b).toFixed(2))
}

export const sub = (a: any, b: any) => {
    a = toNumber(a)
    b = toNumber(b)
    return toNumber((a - b).toFixed(2))
}

export const mul = (a: any, b: any) => {
    a = toNumber(a)
    b = toNumber(b)
    return toNumber((a * b).toFixed(2))
}

export const div = (a: any, b: any) => {
    a = toNumber(a)
    b = toNumber(b)
    return toNumber((a / b).toFixed(2))
}


export const sum6 = (a: any, b: any) => {
    a = toNumber(a, 6)
    b = toNumber(b, 6)
    return toNumber((a + b).toFixed(6), 6)
}

export const sub6 = (a: any, b: any) => {
    a = toNumber(a, 6)
    b = toNumber(b, 6)
    return toNumber((a - b).toFixed(6), 6)
}

export const mul6 = (a: any, b: any) => {
    a = toNumber(a, 6)
    b = toNumber(b, 6)
    return toNumber((a * b).toFixed(6), 6)
}

export const div6 = (a: any, b: any) => {
    a = toNumber(a, 6)
    b = toNumber(b, 6)
    return toNumber((a / b).toFixed(6), 6)
}

export const hasValue = (v: any) => v !== undefined && v!== null && v !== '' && v !== 0 && v !== '0' && v !== '0,00' && v !== '0.00'
