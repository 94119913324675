import styled from "styled-components";
import {Column} from "./flex";
import {Title} from "../components/Titles";
import {primaryTextColor} from "./typography";
import {primaryColor, primaryColorLight, secundaryColor, secundaryColorLight} from "./variables";

export const PageContent = styled.div`
${Column};
    max-width: 100%;
    height: 100%;
    padding: 2rem;
`

export const PageContainer = styled.div`
   display: grid;
        grid-template-columns: 4fr 1fr;
        grid-column-gap: 2rem;
        margin-top: 8rem;
`

export const PageSubContainer = styled.div`
     display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 2rem;
`


export const PageTitle = styled(Title)`
${primaryTextColor};
margin-top: 2rem;
`

export const TextSubtitle = styled.span`
text-align: right;
`

export const CardItem = styled.div`
 background-color: ${primaryColor};
        color: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        box-shadow: 0 0 .5rem -.1rem rgba(0, 0, 0, 0.5);
        transition: all .3s;
        user-select: none;

        &:hover {
            transition: all .3s;
            background-color: ${primaryColorLight};
        }

        i {
            font-size: 4rem;
            display: block;
            width: 4rem;
            margin: 8rem auto 12rem;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                width: 250%;
                height: 250%;
                border: .8rem solid rgba(255, 255, 255, 1);
                border-radius: 50%;
            }

        }

`

export const SecundaryCardItem = styled(CardItem)`
background-color: ${secundaryColor};
        &:hover {
            background-color: ${secundaryColorLight};
        }

        i {
            font-size: 2.8rem;
            width: 2.8rem;
            margin: 2rem auto 6rem;
        }
`

export const CardPequeno = styled.div`
cursor: pointer;
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-gap: 2rem;
        background-color: ${primaryColor};
        color: #FFF;
        margin-bottom: 4rem;
        margin-right: 1rem;
        padding: 1rem;
        align-items: center;
        box-shadow: 0 0 .5rem -.1rem rgba(0, 0, 0, 0.5);

        .descricao {
            display: flex;
            flex-direction: column;

            span:nth-child(1) {
                font-weight: bold;
                margin-bottom: 1rem;
            }

        }

        i {
            font-size: 1.9rem;
            display: block;
            width: 1.9rem;
            position: relative;


            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-48%);
                width: 200%;
                height: 200%;
                border: .2rem solid rgba(255, 255, 255, 1);
                border-radius: 50%;
            }

        }
`

export const CardPequenoSecundary = styled(CardPequeno)`
background-color: ${secundaryColor};
`
