import styled from "styled-components";

export const Table = styled.table`
width: 100%;
`

export const THead = styled.thead`

`

export const TBody = styled.tbody`

`

export const Tr = styled.tr`
`

export const Th = styled.th`
text-align: left;
padding: 0 .8rem .8rem;
`

export const Td: any = styled.td`
padding: .8rem;

${(props: any) => {
    const styles: any = {}
    if(props.color) styles.color = props.color
    if(props.fontWeight) styles.fontWeight = props.fontWeight
    
    return styles
}}
`
