import React from 'react';
import { Route } from 'react-router-dom';
import {TOKEN_STORAGE_KEY} from "./apiConstants";

const isAuthenticated = () => !!sessionStorage.getItem(TOKEN_STORAGE_KEY)

const getUrlToRedirect = () => {
    if(process.env.NODE_ENV === 'development') {
        return 'http://localhost:4200/login'
    }
    return 'https://app.toqweb.com.br/login'
}

const ProtectedRoute = ({component: Component, ...rest}: any) => {
    return (
        <Route {...rest} render={props => isAuthenticated() ? <Component {...props} />
            : window.location.replace(getUrlToRedirect())} />
        // : <Redirect to={{pathname: '/', state: {from: props.location}}} />} />
    );
};

export default ProtectedRoute;
