export class OrcamentoPedidoVendaDet {
    Id: number = 0;
    Quantidade: number = 0;
    ValorUnitario: number = 0;
    ValorSubtotal: number = 0;
    TaxaDesconto: number = 0;
    ValorDesconto: number = 0;
    ValorTotal: number = 0;
    TaxaComissao: number = 0;
    ValorComissao: number = 0;
    BaseCalculoIcmsSt: number = 0;
    ValorIcmsSt: number = 0;
    AliquotaIpi: number = 0;
    ValorIpi: number = 0;
    CmvApurado: number = 0;
    QtdAFaturar: number = 0;
    ValorDescontoRateio: number = 0;
    ValorAcrescimoRateio: number = 0;
    TaxaAcrescimo: number = 0;
    ValorAcrescimo: number = 0;
    QtdEntregue: number = 0;
    FatorConversao: number = 0;
    Peso: number = 0;
    NumeroItem: number = 0;
    PrecoCusto: number = 0;
    CodigoInterno: string = '';
    Uid: string = '';
    Descricao: string = '';
    ValorEmbalagem: number = 0;
    QtdEmbalagem: number = 0;
    UnidEmbalagem: string = '';
    IdOrcamentoVendaCabecalho: any = '';
    IdProduto: any = 0;
    IdVendedor: any = '';
    IdLocal: any = '';
    IdGerente: any = '';
    IdUnidConversao: any = '';
    //OpvdEntregueList: any[] = [];
    //OpvdLoteList: any[] = [];
    //OpvdProducaoList: any[] = [];
    //OpvdVariacaoList: any[] = [];


    static relacionamentos() {
        return ['IdProduto', 'IdVendedor', 'IdLocal', 'IdGerente', 'IdUnidConversao']
    }

    static referencias() {
        return [{chave: 'IdProduto', referencia: 'Produto'}, {chave: 'IdVendedor', referencia: 'Vendedor'}, {chave: 'IdLocal', referencia: 'IdLocal'},
            {chave: 'IdGerente', referencia: 'IdGerente'}, {chave: 'IdUnidConversao', referencia: 'UnidadeProduto'}]
    }

}
