import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {Action} from "../../models/action.model";
import api from "../../config/network";
import {URL_BANCO_STORAGE_KEY} from "../../config/apiConstants";
import {push} from "connected-react-router";
import {State} from "../reducers";
import {Sangria} from "../../models/sangria.model";
import { toast } from "react-toastify";
import {formataValor} from "../../util/formatacao";
import {Movimento} from "../../models/movimento.model";
import {lowerFirstLetter} from "../../util/transform_case";

export const Types = {
    LIST: 'caixa/LIST',
    LIST_SUCCESS: 'caixa/LIST_SUCCESS',
    VERIFICAR_MOVIMENTO: 'caixa/VERIFICAR_MOVIMENTO',
    VERIFICAR_MOVIMENTO_SUCCESS: 'caixa/VERIFICAR_MOVIMENTO_SUCCESS',
    SHOW_MODAL_SANGRIA: 'modalSangria/EXIBIR',
    HIDDEN_MODAL_SANGRIA: 'modalSangria/ESCONDER',
    LIST_NATUREZA: 'modalSangria/LIST_NATUREZA',
    LIST_NATUREZA_SUCCESS: 'modalSangria/LIST_NATUREZA_SUCCESS',
    SALVA_SANGRIA: 'modalSangria/SALVA_SANGRIA',
    SALVA_SANGRIA_SUCCESS: 'modalSangria/SALVA_SANGRIA_SUCCESS',
}

export interface CaixaTypes {
    caixas: any[]
    movimento: Movimento
    modalSangriaVisible: boolean
    naturezas: any[]
}

const INITIAL_STATE = {
    caixas: [],
    movimento: null,
    modalSangriaVisible: false,
    naturezas: [],
}

export const Creators = {
    listCaixas: () => ({type: Types.LIST}),
    listCaixasSuccess: (payload: any) => ({type: Types.LIST_SUCCESS, payload}),
    verificarMovimento: (caixaId: number) => ({type: Types.VERIFICAR_MOVIMENTO, payload: {caixaId}}),
    verificarMovimentoSuccess: (movimento: any) => ({type: Types.VERIFICAR_MOVIMENTO_SUCCESS, payload: movimento}),
    exibirModalSangria: () => ({type: Types.SHOW_MODAL_SANGRIA}),
    esconderModalSangria: () => ({type: Types.HIDDEN_MODAL_SANGRIA}),
    listNatureza: () => ({type: Types.LIST_NATUREZA}),
    listNaturezaSuccess: (payload: any) => ({type: Types.LIST_NATUREZA_SUCCESS, payload}),
    salvaSangria: (payload: any) => ({type: Types.SALVA_SANGRIA, payload}),
    salvaSangriaSuccess: (payload: any) => ({type: Types.SALVA_SANGRIA_SUCCESS, payload}),
}

export const caixa = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case Types.LIST_SUCCESS:
            return {...state, caixas: action.payload}
        case Types.VERIFICAR_MOVIMENTO_SUCCESS:
            return {...state, movimento: action.payload}
        case Types.SHOW_MODAL_SANGRIA:
            return {...state, modalSangriaVisible: true}
        case Types.HIDDEN_MODAL_SANGRIA:
            return {...state, modalSangriaVisible: false}
        case Types.LIST_NATUREZA_SUCCESS:
            return {...state, naturezas: action.payload}
        default:
            return state
    }
}


function* listCaixas({payload}: Action) {
    try {
        let {data} = yield call(api.get, `/cad/${sessionStorage.getItem(URL_BANCO_STORAGE_KEY)}/caixapdv`)
        data = data.value

        yield put(Creators.listCaixasSuccess(data))
    } catch (e) {
        console.log(e)
    }
}


function* listNaturezas() {
    try {
        let {data} = yield call(api.get, `/cad/${sessionStorage.getItem(URL_BANCO_STORAGE_KEY)}/naturezaFinanceira?$filter=(Sintetico eq false)`)
        data = data.value.map((value: any) => ({
            label: value.Descricao,
            value: value.Id,
        }))

        yield put(Creators.listNaturezaSuccess(data))
    } catch (e) {
        console.log(e)
    }
}


function* verificarMovimento({payload}: Action) {
    try {
        let {data} = yield call(api.get, `/venda/${sessionStorage.getItem(URL_BANCO_STORAGE_KEY)}/VerificarMovimentoPdv?IdCaixaPdv=${payload.caixaId}`)
        yield put(Creators.verificarMovimentoSuccess(lowerFirstLetter(data)))
        yield put(push('/inicio'))
    } catch (e) {
        console.log(e)
    }
}


function* salvaNovaSangria({payload}: Action) {
    try {

        const idMovimento = yield select((state: State) => state.caixa?.movimento?.id)

        const sangria: Sangria = {
            ...payload,
            "IdMovimento@xdata.ref": `Movimento(${idMovimento})`,
        }

        let {data} = yield call(api.post, `/venda/${sessionStorage.getItem(URL_BANCO_STORAGE_KEY)}/novaSangria`, sangria)
        yield put(Creators.salvaSangriaSuccess(data))
        yield put(Creators.esconderModalSangria())
        toast.success(`🚀 Sangria de ${formataValor(payload.valor)} efetuada!`)
    } catch (e) {
        console.log(e)
    }
}


export function* caixaSaga(): any {
    yield all([
        takeEvery(Types.LIST, listCaixas),
        takeEvery(Types.VERIFICAR_MOVIMENTO, verificarMovimento),
        takeEvery(Types.LIST_NATUREZA, listNaturezas),
        takeEvery(Types.SALVA_SANGRIA, salvaNovaSangria),
    ])
}
