import {Action} from "../../models/action.model";
import {all, takeEvery, call, select, put} from "redux-saga/effects";
import api from "../../config/network";
import {State} from "../reducers";
import {Types as EmpresaActions} from './empresa'
import {API_CLIMA_KEY} from "../../config/apiConstants";

export const Types = {
    PUT_INFO: 'configuracao/PUT_INFO',
    VERIFICAR_TEMPERATURA: 'configuracao/VERIFICAR_TEMPERATURA',
    VERIFICAR_TEMPERATURA_SUCCESS: 'configuracao/VERIFICAR_TEMPERATURA_SUCCESS',
}

export interface ConfiguracaoTypes {
    urlApi: string
    nomeBanco: string
    token: string
    temperatura: string
}

const INITIAL_STATE: ConfiguracaoTypes = {
    urlApi: null,
    nomeBanco: null,
    token: null,
    temperatura: null,
}

export const ConfiguracaoCreators = {
    putConfigInfo: (value: any) => ({type: Types.PUT_INFO, payload: value}),
    verificarTemperatura: () => ({type: Types.VERIFICAR_TEMPERATURA}),
    verificarTemperaturaSuccess: (value: string) => ({type: Types.VERIFICAR_TEMPERATURA_SUCCESS, payload: value}),
}

export const configuracao = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case Types.PUT_INFO:
            const {nomeBanco, urlApi, token} = action.payload
            return {...state, nomeBanco, urlApi, token}
        case Types.VERIFICAR_TEMPERATURA_SUCCESS:
            return {...state, temperatura: action.payload}
        default:
            return state
    }
}


function* verificarTemperatura() {
    try {
        const {cidade, uf} = yield select((state: State) => {
            return {
                cidade: state.empresa?.clienteToqsys?.cidade,
                uf: state.empresa?.clienteToqsys?.uf,
            }
        })
        const {data} = yield call(api.get, `https://api.hgbrasil.com/weather?key=${API_CLIMA_KEY}&format=json-cors&fields=temp&city_name=${cidade},${uf}`)
        ConfiguracaoCreators.verificarTemperaturaSuccess(data?.results?.temp)
    } catch (e) {
        console.log(e)
    }
}

function* iniciaVerificacaoTemperatura() {
    yield put(ConfiguracaoCreators.verificarTemperatura())
}

export function* configuracaoSaga(): any {
    yield all([
        takeEvery(EmpresaActions.BUSCA_CLIENTE_TOQSYS_SUCCESS, iniciaVerificacaoTemperatura),
        takeEvery(Types.VERIFICAR_TEMPERATURA, verificarTemperatura)
    ])
}
