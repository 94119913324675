import React from 'react';
import {Provider} from 'react-redux';
import store from './store'
import RoutesConfig from './config/RoutesConfig';
import ModalRoot from "./modais/ModalRoot";
import {ToastContainer} from 'react-toastify';
import {PersistGate} from 'redux-persist/integration/react'

import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import {pt} from 'date-fns/locale';

registerLocale('pt', pt)
setDefaultLocale('pt', pt)

function App() {
    return (
        <Provider store={store.store}>
            <PersistGate loading={null} persistor={store.persistor}>
                <RoutesConfig/>
                <ModalRoot/>
                <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false}
                                newestOnTop={false} closeOnClick rtl={false} draggable pauseOnHover/>
            </PersistGate>
        </Provider>
    );
}

export default App;
