import React from 'react';
import {VendaCard} from "../../components/CardVenda";
import styled from "styled-components";
import {Row} from "../../styles/flex";
import {colorGreen, colorRed, darkColor, primaryColor} from "../../styles/variables";
import {connect} from "react-redux";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import {calcTotalAReceber, calcTotalRecebido, calcValorTotal} from "../../store/sagas/pedido";
import {Table, TBody, Td, Th, THead, Tr} from "../../components/Table";
import {CondicaoPagamento} from "../../models/condicao_pagamento.model";
import {formataValor} from "../../util/formatacao";

const CardDetalhePagamento = ({totalPedido, totalRecebido, pagamentos, totalAReceber, desconto}: any) => {

    return (
        <Card>
            <Header>
                <Title>FORMA DE PAGAMENTO E VALOR</Title>
            </Header>
            <Body>
                <TotaisContainer>
                    <TotalAzul>
                        <span>Total Venda:</span>
                        <span>{formataValor(totalPedido)}</span>
                    </TotalAzul>
                    <TotalVermelho>
                        <span>Desconto:</span>
                        <span>{formataValor(desconto)}</span>
                    </TotalVermelho>
                    <TotalAzul>
                        <span>Acrescimo:</span>
                        <span>0,00</span>
                    </TotalAzul>
                    <TotalAzul>
                        <span>Total a Receber:</span>
                        <span>{formataValor(totalAReceber)}</span>
                    </TotalAzul>
                    <TotalVerde>
                        <span>Total Recebido:</span>
                        <span>{formataValor(totalRecebido)}</span>
                    </TotalVerde>
                    <TotalAzul>
                        <span>Saldo Restante:</span>
                        <span>0,00</span>
                    </TotalAzul>
                    <TotalVermelho>
                        <span>Troco:</span>
                        <span>0,00</span>
                    </TotalVermelho>
                </TotaisContainer>
                <Table>
                    <THead>
                        <Tr>
                            <Th>DESCRICAO</Th>
                            <Th>VALOR</Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {pagamentos.map((pagamento: CondicaoPagamento, index: number) =>
                            <Tr key={index}>
                               <Td>{pagamento.descricao}</Td>
                               <Td>{formataValor(pagamento.valor)}</Td>
                            </Tr>
                        )}
                    </TBody>
                </Table>
            </Body>
        </Card>
    );
};


const mapStateToProps = (state: State) => {

    return {
        pagamentos: state.pedido.pagamentos,
        totalPedido: calcValorTotal(state),
        totalRecebido: calcTotalRecebido(state),
        totalAReceber: calcTotalAReceber(state),
        desconto: state.pedido.desconto,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CardDetalhePagamento);


const Card = styled(VendaCard)`

`

const Header = styled.div`
${Row};
color: #FFF;
background-color: ${darkColor};
padding: .8rem 1.6rem;
`

const Title = styled.span`

`

const Body = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-column-gap: 2rem;
padding: 1rem 2rem;
`

const TotaisContainer = styled.div`
display: flex;
flex-direction: column;
font-size: 1.2rem;
margin-top: .5rem;
`

const Totais = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 2rem;

span {
font-size: 2rem;
}
`

const TotalAzul = styled(Totais)`
color: ${primaryColor};
`

const TotalVerde = styled(Totais)`
color: ${colorGreen};
`

const TotalVermelho = styled(Totais)`
color: ${colorRed};
`
