import {Empresa} from "../../models/empresa.model";
import {ClienteToqsys} from "../../models/cliente_toqsys.model";
import {Action} from "../../models/action.model";
import {all, call, put, takeEvery} from "redux-saga/effects";
import api from "../../config/network";
import {lowerAll} from "../../util/transform_case";

export const Types = {
    BUSCA_EMPRESA: 'empresa/BUSCA_EMPRESA',
    BUSCA_EMPRESA_SUCCESS: 'empresa/BUSCA_EMPRESA_SUCCESS',
    BUSCA_CLIENTE_TOQSYS: 'empresa/BUSCA_CLIENTE_TOQSYS',
    BUSCA_CLIENTE_TOQSYS_SUCCESS: 'empresa/BUSCA_CLIENTE_TOQSYS_SUCCESS',
}

export interface EmpresaTypes {
    empresa: Empresa
    clienteToqsys: ClienteToqsys
}

const INITIAL_STATE: EmpresaTypes = {
    empresa: null,
    clienteToqsys: null,
}

export const EmpresaCreators = {
    loadEmpresa: (idEmpresa: number) => ({type: Types.BUSCA_EMPRESA, payload: idEmpresa}),
    loadEmpresaSuccess: (empresa: Empresa) => ({type: Types.BUSCA_EMPRESA_SUCCESS, payload: empresa}),

    loadClienteToqsys: (idClienteToqsys: number) => ({type: Types.BUSCA_CLIENTE_TOQSYS, payload: idClienteToqsys}),
    loadClienteToqsysSuccess: (clienteToqsys: ClienteToqsys) => ({type: Types.BUSCA_CLIENTE_TOQSYS_SUCCESS, payload: clienteToqsys}),
}

export const empresa = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case Types.BUSCA_CLIENTE_TOQSYS_SUCCESS:
            return {...state, clienteToqsys: action.payload}
        default:
            return state
    }
}


function* buscarClienteToqsys({payload}: Action) {
    try {
        let {data} = yield call(api.get, `/security/cliente_toqsys(${payload})`)
        yield put(EmpresaCreators.loadClienteToqsysSuccess(lowerAll(data)))
    } catch (e) {
        console.log(e)
    }
}


export function* empresaSaga(): any {
    yield all([
        takeEvery(Types.BUSCA_CLIENTE_TOQSYS, buscarClienteToqsys),

    ])
}

