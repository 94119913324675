import React from 'react';
import Counter from "../../components/Counter";
import MoneyLabel from "../../components/MoneyLabel";
import styled from "styled-components";
import {Row} from "../../styles/flex";
import {darkColor} from "../../styles/variables";
import {VendaCard} from "../../components/CardVenda";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {PedidoItem} from "../../models/pedido_item.model";
import {Creators as PedidoCreators} from "../../store/sagas/pedido";

interface PropTypes {
    ultimoItem: PedidoItem
    aumentaQuantidade: Function
    diminuiQuantidade: Function
    alteraQuantidade: Function
}

const CardAdicionarProduto = ({ultimoItem, aumentaQuantidade, diminuiQuantidade, alteraQuantidade}: PropTypes) => {

    return (
        <VendaCard>
            <InserirProdutoCardHeader>
                <i className="fas fa-tag"/>
                <ProductName>{ultimoItem ? ultimoItem?.idProduto?.Nome : 'Insira um produto pelo codigo ou pelo leitor'}</ProductName>
            </InserirProdutoCardHeader>
            <ProductDetail>
                <ProductImageContainer>
                    {
                        ultimoItem?.idProduto.Foto ? <ProductImage src={ultimoItem?.idProduto?.Foto} /> :
                        <i className="fas fa-box" style={{color: 'rgba(0,0,0,.2)', fontSize: '12rem'}}/>
                    }
                </ProductImageContainer>
                <div>
                    <div>
                        <Label>QUANTIDADE</Label>
                        <Counter value={ultimoItem?.quantidade} handlePlus={aumentaQuantidade} handleMinus={diminuiQuantidade} changeValue={alteraQuantidade} />
                    </div>
                    <div>
                        <Label>VALOR UNITARIO</Label>
                        <MoneyLabel valor={ultimoItem?.valorUnitario}/>
                    </div>
                    <div>
                        <Label>VALOR TOTAL</Label>
                        <MoneyLabel bold valor={ultimoItem?.valorTotal}/>
                    </div>
                </div>
            </ProductDetail>
        </VendaCard>
    );
};

const mapStateToProps = (state: State) => {

    const pedidosItens = state.pedido.pedidoItens
    const ultimoItem = !pedidosItens.length ? null : pedidosItens[pedidosItens.length - 1]

    return {
        ultimoItem,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    aumentaQuantidade: () => dispatch(PedidoCreators.aumentaQuantidadeUltimoItem()),
    diminuiQuantidade: () => dispatch(PedidoCreators.diminuiQuantidadeUltimoItem()),
    alteraQuantidade: (quantidade: number) => dispatch(PedidoCreators.alteraQuantidadeUltimoItem(quantidade)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardAdicionarProduto);


const InserirProdutoCardHeader = styled.div`
 ${Row};
 justify-content: stretch;               
                padding: 0 3rem;
               min-height: 8rem;
                border-bottom: .1rem solid rgba(0, 0, 0, .2);

                i {
                    font-size: 2.5rem;
                    color: rgb(254, 148, 46);
                    margin-right: 4rem;
                    padding: .5rem;
                    position: relative;
                    display: inline-block;

                    &::after {
                        content: '';
                        width: 150%;
                        height: 150%;
                        border: .3rem solid rgb(254, 148, 46);
                        border-radius: 20rem;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                    }
                }
`

const ProductName = styled.h1`
font-size: 2.6rem;
color: ${darkColor};
font-weight: bold;
`

const ProductDetail = styled.div`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 2rem;
        margin-top: 2rem;
         padding-bottom: 2rem;
`

const ProductImageContainer = styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
`

const Label = styled.span`
display: block;
font-size: 1.6rem;
margin: 1rem 0 .5rem;
`

const ProductImage = styled.img`
max-width: 12rem;
max-height: 12rem;
`
