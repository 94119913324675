import {all} from 'redux-saga/effects'
import {pedidoSaga} from "./pedido";
import {caixaSaga} from "./caixa";
import {empresaSaga} from "./empresa";
import {configuracaoSaga} from "./configuracao";
import {pesquisaPrecoSaga} from "./pesquisaPreco";
import {pesquisaPessoaSaga} from "./pesquisaPessoa";

function* rootSaga() {
    yield all([
        ...pedidoSaga(),
        ...caixaSaga(),
        ...empresaSaga(),
        ...configuracaoSaga(),
        ...pesquisaPrecoSaga(),
        ...pesquisaPessoaSaga(),
    ])
}

export default rootSaga
