import React, {useRef} from 'react';
import {VendaCard} from "../../components/CardVenda";
import styled from "styled-components";
import {Row} from "../../styles/flex";
import {darkColor, lightColor, secundaryColor, whiteTransparentColor} from "../../styles/variables";
import {Table, TBody, Td, Th, THead, Tr} from "../../components/Table";
import MoneyLabel from "../../components/MoneyLabel";
import {TextBig} from "../../components/Typography";
import {connect} from "react-redux";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import {PedidoItem} from "../../models/pedido_item.model";
import {calcTotalItemSelecionado, calcValorTotal, Creators as PedidoCreators} from "../../store/sagas/pedido";
import {formataValor} from "../../util/formatacao";
import QtdItemTable from "../../components/QtdItemTable";

const CardProdutosAdicionados = ({pedidoItens, totalPedido, editaItem, aumentaQuantidade, diminuiQuantidade}: any) => {

    const refCorpo: any = useRef()

    const scrollUp = () => {
        if(refCorpo && refCorpo.current) {
            refCorpo.current.scrollTo({top: refCorpo.current.scrollTop - 100, left: 0, behavior: "smooth"})
        }
    }

    const scrollDown = () => {
        if(refCorpo && refCorpo.current) {
            refCorpo.current.scrollTo({top: refCorpo.current.scrollTop + 100, left: 0, behavior: "smooth"})
        }
    }

    return (
        <Card>
            <Top>
                <Header>
                    <Title>ITENS DA COMPRA</Title>
                    <Actions>
                        <span>Rolar itens</span>
                        <i className="fas fa-arrow-up" onClick={scrollUp}/>
                        <i className="fas fa-arrow-down" onClick={scrollDown}/>
                    </Actions>
                </Header>
                <Corpo ref={refCorpo}>
                    <TableItens>
                        <THead>
                            <Tr>
                                <Th>#</Th>
                                <Th>ITEM</Th>
                                <Th>QTDE.</Th>
                                <Th>(R$)</Th>
                                <Th/>
                            </Tr>
                        </THead>
                        <TBody>
                            {pedidoItens.map((item: PedidoItem, index: number) =>
                                <Tr key={index}>
                                    <Td color={secundaryColor}>{index + 1}</Td>
                                    <Td>{item.idProduto?.Nome.substring(0, 40)}</Td>
                                    <Td fontWeight={'bold'}><QtdItemTable quantidade={item.quantidade} handlePlus={() => aumentaQuantidade(index)} handleMinus={() => diminuiQuantidade(index)}/></Td>
                                    <Td color={secundaryColor}>{formataValor(item.valorTotal)}</Td>
                                    <td><i className="far fa-edit" style={{cursor: 'pointer'}} onClick={() => editaItem(index)}/></td>
                                </Tr>
                            )}
                        </TBody>
                    </TableItens>
                </Corpo>
            </Top>
            <TotalBottom>
                <TextTotal>TOTAL A PAGAR</TextTotal>
                <MoneyLabel bold valor={totalPedido}/>
            </TotalBottom>
        </Card>
    );
};


const mapStateToProps = (state: State) => {
    const {pedidoItens} = state.pedido

    return {
        pedidoItens: pedidoItens.map((pedidoItem: PedidoItem, index: number): PedidoItem => {
            return {...pedidoItem, valorTotal: calcTotalItemSelecionado(state, index)}
        }),
        totalPedido: calcValorTotal(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    editaItem: (itemIndex: number) => dispatch(PedidoCreators.handleAlteraItem(itemIndex)),
    aumentaQuantidade: (itemIndex: number) => dispatch(PedidoCreators.handleAumentaQuantidadeItemIndex(itemIndex)),
    diminuiQuantidade: (itemIndex: number) => dispatch(PedidoCreators.handleDiminuiQuantidadeItemIndex(itemIndex)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardProdutosAdicionados);


const Card = styled(VendaCard)`
justify-content: space-between;
overflow-y: auto;
position: relative;
`

const Top = styled.div`

`

const Header = styled.div`
${Row};
color: #FFF;
background-color: ${darkColor};
padding: .8rem 1.6rem;
`

const Title = styled.span`

`

const Actions = styled.div`
user-select: none;
span, i {
margin-right: .8rem;
}

i {
    padding: .8rem;
    position: relative;
    display: inline-block;
    cursor: pointer;

    &::after {
        content: '';
        width: 80%;
        height: 70%;
        border: .2rem solid ${whiteTransparentColor};
        border-radius: .2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1000;
    }

}
`

const Corpo = styled.div`
max-height: 40vh;
overflow-y: auto;
`

const TableItens = styled(Table)`
margin-top: 1rem;
`

const TotalBottom = styled.div`
${Row};
height: 6rem;
padding: 0 4rem;
border-top: .2rem solid ${lightColor};
background-color: #fff;
`

const TextTotal = styled(TextBig)`
font-size: 3.2rem;
letter-spacing: .4rem;
`

