import styled from "styled-components";
import {secundaryColor} from "../styles/variables";
import {Column} from "../styles/flex";

export const VendaCard = styled.div`
position: relative;
${Column};
  background-color: #FFF;
  height: 100%;
  border-bottom: .5rem solid ${secundaryColor};
  box-shadow: 0 0 .5rem -.1rem rgba(0, 0, 0, 0.5);
`