export const upperFirstLetter = origObj => {
    if(!origObj) return origObj

    return Object.keys(origObj).reduce((newObj, key) => {
        const val = origObj[key];
        newObj[key.charAt(0).toUpperCase() + key.slice(1)] = (typeof val === 'object') ? upperFirstLetter(val) : val;
        return newObj;
    }, {});
}


export const lowerFirstLetter = value => {
    if(!value) return value

    if(Array.isArray(value)) {
        return value.map(v => convertToLower(v))
    }

    return convertToLower(value)
}

const convertToLower = value => {
    return Object.keys(value).reduce((newObj, key) => {
        const val = value[key];
        const newKey = key.charAt(0).toLowerCase() + key.slice(1)
        newObj[snakeToCamel(newKey)] = (typeof val === 'object') ? lowerFirstLetter(val) : val;
        return newObj;
    }, {});
}

export const lowerAll = value => {
    if(!value) return value

    if(Array.isArray(value)) {
        return value.map(v => convertAllToLower(v))
    }

    return convertAllToLower(value)
}

const convertAllToLower = value => {
    return Object.keys(value).reduce((newObj, key) => {
        const val = value[key];
        const newKey = key.toLowerCase()
        newObj[snakeToCamel(newKey)] = (typeof val === 'object') ? lowerFirstLetter(val) : val;
        return newObj;
    }, {});
}

export const snakeToCamel = str => str.replace(/([-_]\w)/g, g => g[1].toUpperCase())

