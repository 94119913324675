import React from 'react';
import styled from "styled-components";
import {Column, Row} from "../../styles/flex";
import {darkColor} from "../../styles/variables";
import {VendaCard} from "../../components/CardVenda";
import InputMonetario from "../../components/InputMonetario";
import {State} from "../../store/reducers";
import {calcTotalAReceber, calcSubTotal, calcTotalRecebido, Creators as PedidoCreators} from "../../store/sagas/pedido";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {toNumber} from "../../util/math_util";

const CardDesconto = ({totalPedido, totalRecebido, totalAReceber, handleDescontoPeloValorAPagar, handleDescontoPeloValorDesconto, desconto}) => {
    return (
        <Card>
            <Header>
                <Title>DESCONTO</Title>
            </Header>
            <Body>
                <InputContainer>
                    <Label>DESCONTO</Label>
                    <InputSubContainer>
                        <Cifrao>R$</Cifrao>
                        <InputValor value={desconto} onChange={handleDescontoPeloValorDesconto}/>
                    </InputSubContainer>
                </InputContainer>
                <InputContainer>
                    <Label>A RECEBER</Label>
                    <InputSubContainer>
                        <Cifrao>R$</Cifrao>
                        <InputValor value={totalAReceber} onChange={handleDescontoPeloValorAPagar}/>
                    </InputSubContainer>
                </InputContainer>

            </Body>
        </Card>
    );
};

const mapStateToProps = (state: State) => {
    return {
        totalPedido: calcSubTotal(state),
        totalRecebido: calcTotalRecebido(state),
        totalAReceber: calcTotalAReceber(state),
        desconto: state?.pedido?.desconto || 0,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleDescontoPeloValorAPagar: (valor: number) => dispatch(PedidoCreators.handleDescontoPeloValorAPagar(toNumber(valor))),
    handleDescontoPeloValorDesconto: (valor: number) => dispatch(PedidoCreators.handleDescontoPeloValorDesconto(toNumber(valor))),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardDesconto);


const Card = styled(VendaCard)`

`

const Header = styled.div`
${Row};
color: #FFF;
background-color: ${darkColor};
padding: .8rem 1.6rem;
`

const Title = styled.span`

`

const Body = styled.div`
${Column};
height: 100%;
padding-top: 4rem;
align-items: center;
`

const InputContainer = styled.div`
${Column};
justify-content: center;
overflow: hidden;
margin-left: 2rem;
margin-top: 4rem;
`

const Label = styled.span`
display: block;
font-size: 2rem;
margin-bottom: 1rem;
`

const InputSubContainer = styled.div`
display: grid;
grid-template-columns: 3rem 1fr;
align-items: end;
`

const Cifrao = styled.span`
color: rgb(254, 148, 46);
font-size: 1.8rem;
margin-bottom: 1rem;
`

const InputValor = styled(InputMonetario)`
border: none;
display: inline-block;
color: rgb(254, 148, 46);
font-size: 4rem;
width: 15rem;
`
