import React from 'react';
import {CardItem, PageContainer, PageContent, PageSubContainer, PageTitle, SecundaryCardItem, TextSubtitle} from "../styles/commonStyles";
import {push} from "connected-react-router";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {Creators as CaixaCreators} from "../store/sagas/caixa";
import {PesquisaPrecoCreators} from "../store/sagas/pesquisaPreco";

const Home = ({navigate, openModalNovaSangria, openModalPesquisa}: any) => {

    const handleNavigation = (route: string) => {
        navigate(route)
    }

    return (
        <PageContent>
            <PageTitle>FUNÇÕES DO OPERADOR</PageTitle>
            <PageContainer>
                <PageSubContainer>
                    <TextSubtitle>Funções gerais</TextSubtitle>
                    <CardItem className={'ajuste-icon-esquerda'} onClick={() => handleNavigation('/venda')}>
                        <span>F2</span>
                        <i className="fas fa-shopping-cart"/>
                        <span>INICIAR VENDA</span>
                    </CardItem>
                    <CardItem className={'ajuste-icon-direita'} onClick={openModalNovaSangria}>
                        <span>F3</span>
                        <i className="fas fa-receipt"/>
                        <span>NOVA SANGRIA</span>
                    </CardItem>
                    <CardItem onClick={openModalPesquisa}>
                        <span>F4</span>
                        <i className="fas fa-search"/>
                        <span>CONSULTAR PREÇO</span>
                    </CardItem>

                    <TextSubtitle>Funções restritas ao supervisor</TextSubtitle>
                    <SecundaryCardItem>
                        <span>F7</span>
                        <i className="fas fa-archive"/>
                        <span>SUPRIMENTO</span>
                    </SecundaryCardItem>
                </PageSubContainer>
            </PageContainer>
        </PageContent>
    );
};


const mapDispatchToProps = (dispatch: Dispatch) => ({
    navigate: (route: string) => dispatch(push(route)),
    openModalNovaSangria: () => dispatch(CaixaCreators.exibirModalSangria()),
    openModalPesquisa: () => dispatch(PesquisaPrecoCreators.openModalPesquisa()),
})

export default connect(null, mapDispatchToProps)(Home);
