import React from 'react';
import styled from "styled-components";
import {secundaryColor} from "../styles/variables";
import {formataValor} from "../util/formatacao";

const MoneyLabel = ({children, bold, valor}: any) => {
    return (
        <MoneyLabelStyle>
            <Cifrao>R$</Cifrao>
            <Value bold={bold}>{formataValor(valor)}</Value>
        </MoneyLabelStyle>
    );
};

export default MoneyLabel;

const MoneyLabelStyle = styled.div`
color: ${secundaryColor};
`

const Cifrao = styled.span`
font-size: 1.9rem;
margin-right: .5rem;
`

const Value: any = styled.span`
font-size: 3.2rem;
${(props: any) => props.bold && ({
    fontWeight: 'bold'
}) };
`
