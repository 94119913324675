import React from 'react';
import styled from "styled-components";
import {Row} from "../styles/flex";
import {PrimaryButton, RedButton} from "../components/Button";
import {colorRed} from "../styles/variables";

const BaseModal: any = ({children, isVisible, width, closeModal, handleConfirm, isSearchOnly}) => {
    return (
        <ModalContainer isVisible={isVisible}>
            <ModalBody width={width}>
                <CloseButton onClick={closeModal} />
                {children}
                <ModalFooter>
                    <CancelarButton onClick={closeModal}>Cancelar</CancelarButton>
                    {isSearchOnly ? null : <PrimaryButton onClick={handleConfirm}>Confirmar</PrimaryButton>}
                </ModalFooter>
            </ModalBody>
        </ModalContainer>
    );
};

export default BaseModal;


const ModalContainer: any = styled.div`
overflow: hidden;
position: fixed;
background: rgba(0,0,0,.4);
${({isVisible}: any) => isVisible ? ({
    width: '100vw',
    height: '100vh',
    left: 0,
    top: 0,
}) : ({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    opacity: 0,
    pointerEvents: 'none',
})};
`

const ModalBody: any = styled.div`
position: absolute;
width: ${({width}: any) => width || '80%'};
padding: 4rem 4rem 1rem;
background-color: #fff;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`

const ModalFooter = styled.div`
${Row};
justify-content: flex-end;
margin-top: 3rem;
`

const CancelarButton: any = styled(RedButton)`
margin-right: 2rem;
`

const CloseButton: any = styled.div.attrs(() => ({
    children: <i className={"fas fa-times"}/>,
}))`
width: 4rem;
height: 4rem;
position: absolute;
top: 0;
right: 4rem;
background-color: ${colorRed};
cursor: pointer;
color: #fff;
font-size: 2rem;
${Row};
align-items: center;
justify-content: center;
`
