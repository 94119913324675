export class OrcamentoPedidoVendaCab {

    Id: number = 0;
    Codigo: string = '';
    DataCadastro: any = '';
    DataPrevistaEntrega: any = '';
    DataFaturamento: any = '';
    DataSaida: any = '';
    Validade: any = '';
    TipoFrete: string = '';
    ValorSubtotal: number = 0;
    ValorFrete: number = 0;
    TaxaComissao: number = 0;
    ValorComissao: number = 0;
    TaxaDesconto: number = 0;
    ValorDesconto: number = 0;
    ValorTotal: number = 0;
    Observacoes: string = '';
    BaseCalculoIcmsSt: number = 0;
    ValorIcmsSt: number = 0;
    ValorIpi: number = 0;
    ValorSeguro: number = 0;
    OutrasDespesas: number = 0;
    StatusPedido: string = '';
    Tipo: string = '';
    CmvTotal: number = 0;
    ValorAcrescimo: number = 0;
    TaxaAcrescimo: number = 0;
    IdEmpresa: number = 0;
    CartaoConsumo: number = 0;
    Entregue: string = '';
    PesoTotal: number = 0;
    QtdVolume: number = 0;
    EspecieVolume: string = '';
    IdLocalOrigem: number = 0;
    IdLocalDestino: number = 0;
    Nome: string = '';
    CpfCnpj: string = '';
    Uid: string = '';
    ValorDescontoRateio: number = 0;
    ValorAcrescimoRateio: number = 0;
    IdNaturezaFin: number = 0;
    Estoque: number = 0;
    Financeiro: number = 0;
    Numero: number = 0;
    IdVendedor: any = '';
    IdPessoa: any = '';
    IdCondicoesPagamento: any = '';
    IdEndereco: string = '';
    IdTransportador: string = '';
    MovimentoUid: string = '';
    TotalGeral: number = 0;
    Troco: number = 0;
    //OpvcCondPagtoList: any[] = [];
    //OpvcEntregaList: any[] = [];
    //OrcamentoPedidoVendaDetList: any[] = [];

    static datas() {
        return ['DataCadastro', 'DataPrevistaEntrega', 'DataFaturamento', 'DataSaida']
    }

    static relacionamentos() {
        return ['IdCondicoesPagamento', 'IdEndereco', 'IdPessoa', 'IdVendedor', 'IdTransportador']
    }

    static expanded() {
        return ['IdVendedor', 'IdPessoa', 'IdTransportador', 'IdEndereco', 'OpvcCondPagtoList/OpvcCondPagtoParcelaList', 'OpvcCondPagtoList/IdCondPagto', 'OpvcEntregaList', 'OrcamentoPedidoVendaDetList/IdProduto']
    }

    static referencias() {
        return [{chave: 'IdCondicoesPagamento', referencia: 'CondicoesPagamento'}, {chave: 'IdEndereco', referencia: 'PessoaEndereco'},
            {chave: 'IdPessoa', referencia: 'Pessoa'}, {chave: 'IdTransportador', referencia: 'Pessoa'}, {chave: 'IdVendedor', referencia: 'Vendedor'}]
    }

}
