import React, {useState} from 'react';
import {State} from "../store/reducers";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {PesquisaPrecoCreators} from "../store/sagas/pesquisaPreco";
import styled from "styled-components";
import {InterfacesCreatoes} from "../store/sagas/interfaces";
import {push} from "connected-react-router";
import {Column, Row} from "../styles/flex";
import {primaryColor} from "../styles/variables";
import Input from "../components/Input";
import {Creators as PedidoCreators} from "../store/sagas/pedido";
import {PesquisaPessoaCreators} from "../store/sagas/pesquisaPessoa";

const Funcoes = ({
                     tooglePesquisarProdutoVisibility,
                     changeVisibilityDescontoGlobal,
                     funcoesVisible,
                     navigate,
                     cancelaCupom,
                     tooglePesquisarPessoaVisibility,
                 }) => {

    const [inputValue, setInputValue] = useState('')

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleAction(inputValue)
            setInputValue('')
        }
    }

    const handleAction = (code) => {
        switch (code) {
            case '01':
                navigate('/')
                break;
            case '02':

                break;
            case '03':

                break;
            case '04':

                break;
            case '05':
                tooglePesquisarPessoaVisibility()
                break;
            case '06':
                cancelaCupom()
                break;
            case '07':

                break;
            case '08':
                changeVisibilityDescontoGlobal()
                break;
            case '09':

                break;
            case '10':

                break;
            case '11':
                tooglePesquisarProdutoVisibility()
                break;
            case '97':

                break;
            case '99':

                break;
        }
    }

    return (
        <FuncoesContainer isVisible={funcoesVisible}>
            <ContainerItems>
                <Item onClick={() => handleAction('01')}>01 - Voltar</Item>
                <Item>02 - Encerrar Movimento</Item>
                <Item>03 - Suprimento</Item>
            </ContainerItems>
            <ContainerItems>
                <Item>04 - Sangria</Item>
                <Item onClick={() => handleAction('05')}>05 - Identifica Cliente</Item>
                <Item>06 - Cancelar Cupom</Item>
            </ContainerItems>
            <ContainerItems>
                <Item>07 - Cancelar Item</Item>
                <Item onClick={() => handleAction('08')}>08 - Desconto/Acrescimo</Item>
                <Item>09 - Administrativo TEF</Item>
            </ContainerItems>
            <ContainerItems>
                <Item>10 - Informa Vendedor</Item>
                <Item onClick={() => handleAction('11')}>11 - Pesquisar Produto</Item>
                <Item>97 - Deslogar</Item>
            </ContainerItems>
            <ContainerItems>
                <Item>99 - Imprimir Lista de Funções</Item>
            </ContainerItems>
            <ContainerItems>
                <Input normal={true} placeholder={'Digite o Codigo'} value={inputValue} onChange={e => setInputValue(e.target.value)}
                       onKeyDown={handleKeyDown}/>
            </ContainerItems>
        </FuncoesContainer>
    );
};

const mapStateToProps = (state: State) => {
    return {
        funcoesVisible: state.interfaces.funcoesVisible,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    openModalPesquisaPreco: () => dispatch(PesquisaPrecoCreators.changeModalVisible()),
    changeVisibilityDescontoGlobal: () => dispatch(InterfacesCreatoes.changeVisibilityDescontoGlobal()),
    tooglePesquisarProdutoVisibility: () => dispatch(PesquisaPrecoCreators.changeModalVisible()),
    tooglePesquisarPessoaVisibility: () => dispatch(PesquisaPessoaCreators.changeModalVisible()),
    navigate: (route) => dispatch(push(route)),
    cancelaCupom: () => dispatch(PedidoCreators.clearPedidoState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Funcoes);


const FuncoesContainer: any = styled.div`
position: absolute;
bottom: 0;
width: 100%;
background-color: ${primaryColor};
padding: 2rem;
box-shadow: 0 0 .5rem -.1rem rgba(0, 0, 0, 0.5);
overflow: hidden;
${Row};

${({isVisible}: any) => isVisible ? ({
    height: '10rem',
    transition: 'all .3s',
}) : ({
    height: 0,
    paddingTop: 0,
    paddingBottom: 0,
    transition: 'all .3s',
})};
`

const ContainerItems = styled.div`
${Column};
justify-content: space-between;
height: 100%;
`

const Item = styled.div`
font-size: 1.4rem;
cursor: pointer;
color: #fff;
`
