export const dataParaString = (data) => {
    if (data === undefined || data === null || data === '') return ''
    if(typeof data === 'string') return  data
    let mes = (data.getUTCMonth() + 1).toString()
    if (mes.length === 1) mes = '0' + mes
    let dia = data.getUTCDate().toString()
    if (dia.length === 1) dia = '0' + dia
    return data.getUTCFullYear() + '-' + mes + '-' + dia
}

export const formataData = (data: Date) => {
    if(!data || !(data instanceof Date)) return
    return `${addZero(data.getDate())}/${addZero(data.getMonth() + 1)}/${data.getFullYear()}`;
 }

export const addZero = (value: any): string => {
    if(value.toString().length === 1) {
        value = '0' + value
    }
    return value
}

export const getDayOfWeek = (dia: number) => {
    switch (dia) {
        case 0:
            return 'Domingo'
        case 1:
            return 'Segunda'
        case 2:
            return 'Terça'
        case 3:
            return 'Quarta'
        case 4:
            return 'Quinta'
        case 5:
            return 'Sexta'
        case 6:
            return 'Sábado'
        default:
            return ''
    }
}
