export const primaryColor = 'rgb(84, 156, 206)'
export const primaryColorLight = 'rgba(84, 156, 206, .7)'
export const secundaryColor = 'rgb(254, 148, 46)'
export const secundaryColorLight = 'rgba(254, 148, 46, .7)'

export const colorGreen = 'rgba(0, 128, 0, 0.62)'
export const colorRed = 'rgb(254, 73, 76)'

export const lightColor = 'rgba(0, 0, 0, 0.2)';
export const darkColor = 'rgb(176, 176, 176)';

export const whiteTransparentColor = 'rgba(255, 255, 255, .5)';
