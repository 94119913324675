import React, {useEffect, useState} from 'react';
import BaseModal from "./BaseModal";
import {State} from "../store/reducers";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {PesquisaPrecoCreators} from "../store/sagas/pesquisaPreco";
import {Creators as PedidoCreators} from "../store/sagas/pedido";
import {Produto} from "../models/produto.model";
import Input from "../components/Input";
import styled from "styled-components";
import {formataValor} from "../util/formatacao";

interface ComponentTypes {
    isVisible: boolean
    isSearchOnly: boolean
    closeModal: Function
    produtos: Produto[]
    inputValue: string
    changeInputValue: Function
    handleSearch: Function
    addProduct: Function
}

const PesquisarProdutoModal: any = ({isVisible, closeModal, produtos, inputValue, changeInputValue, handleSearch, addProduct, isSearchOnly}: ComponentTypes) => {

    const [selectedItem, setSelectedItem] = useState<number>(0)

    const confirmProduto = () => {
        addProduct(produtos[selectedItem])
    }

    const handleKeydown = (e: KeyboardEvent) => {
        if (e.code === 'ArrowUp') {
            e.preventDefault()
            setSelectedItem(prev => {
                if (prev > 0) {
                    return prev - 1
                }
                return prev
            })
        } else if (e.code === 'ArrowDown') {
            e.preventDefault()
            setSelectedItem(prev => {
                if (prev > 0) {
                    return prev + 1
                }
                return prev
            })
        } else if (e.code === 'Enter') {
            // if (produtos && produtos.length && produtos[selectedItem]) {
            //     confirmProduto()
            // }
        }
    }

    const handleChangeInput = v => {
        changeInputValue(v)
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeydown)

        return () => {
            return window.removeEventListener('keydown', handleKeydown)
        }
    })

    return (
        <BaseModal width={'60%'} isVisible={isVisible} closeModal={closeModal} hiddenConfirm={confirmProduto} isSearchOnly={isSearchOnly}>
            <Input normal placeholder={'Didite o produto a ser pesquisado'} value={inputValue} onChange={(e) => {
                handleChangeInput(e.target.value)
            }}
                   onBlur={() => handleSearch(inputValue)} onKeyDown={e => {
                if (e.key === 'Enter') {
                    handleSearch(inputValue)
                }
            }}/>
            <ProdutosContainer>
                {
                    produtos.map((produto, index) => {
                        return (
                            <ProdutoItem key={index} isSelected={selectedItem === index} onMouseEnter={() => setSelectedItem(index)}>
                                <Img src={produto.Foto}/>
                                <NomeProduto>{produto.Nome}</NomeProduto>
                                <PrecoProduto>{`R$ ${formataValor(produto.ValorVenda)}`}</PrecoProduto>
                            </ProdutoItem>
                        )
                    })
                }
            </ProdutosContainer>
        </BaseModal>
    );
};


const mapStateToProps = (state: State) => {
    return {
        isVisible: state.pesquisaPreco.modalAberto,
        isSearchOnly: state.pesquisaPreco.apenasPesquisa,
        produtos: state.pesquisaPreco.produtos,
        inputValue: state.pesquisaPreco.inputValue,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(PesquisaPrecoCreators.changeModalVisible()),
    changeInputValue: (text: string) => dispatch(PesquisaPrecoCreators.changeInputValue(text)),
    handleSearch: (v) => dispatch(PesquisaPrecoCreators.searchProductBegin({codigo: v})),
    addProduct: (produto: Produto) => dispatch(PedidoCreators.adicionaProdutoPelaPesquisaPreco(produto))
})

export default connect(mapStateToProps, mapDispatchToProps)(PesquisarProdutoModal);

const ProdutosContainer = styled.div`
width: 100%;
max-height: 45rem;
overflow-x: hidden;
overflow-y: auto;
margin-top: 2rem;
`

const ProdutoItem: any = styled.div`
display: grid;
grid-template-columns: 5rem 1fr 8rem;
grid-column-gap: 2rem;
padding: 0 2rem;
align-items: center;
height: 8rem;
position: relative;

${({isSelected}: any) => isSelected ? ({
    backgroundColor: 'rgba(241,241,241,0.66)',
}) : null};

&::after {
content: '';
position: absolute;
left: 50%;
transform: translateX(-50%);
bottom: 0;
width: 100%;
height: .1rem;
background: linear-gradient(to right, transparent, rgba(215,212,212,0.7), transparent);
}

`

const NomeProduto = styled.div`

`

const PrecoProduto = styled.div`
text-align: right;
`

const Img = styled.img`
width: 5rem;
`
