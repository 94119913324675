import React from 'react';
import styled from "styled-components";
import InputMonetario from "./InputMonetario";

const Input = React.forwardRef(({label = '', containerStyle = {}, normal = false, onChange, onKeyDown, ...props}: any, ref) => {

        return (
            <InputContainer style={containerStyle}>
                <InputLabel>{label}</InputLabel>
                {
                    normal ? <InputNormal onChange={onChange} onKeyDown={onKeyDown} {...props}/> : <InputStyled {...props} ref={ref} onChange={onChange} onKeyDown={onKeyDown}/>
                }
            </InputContainer>
        );
    }
)

export default Input;

const InputContainer: any = styled.div`

`

const InputLabel = styled.div`
margin-bottom: 1rem;
`

const InputStyled = styled(InputMonetario)`
width: 100%;
padding: 1rem;
`

const InputNormal = styled.input`
width: 100%;
padding: 1rem;
`
