import React from 'react';
import CurrencyInput from 'react-currency-input';

const InputMonetario: any = React.forwardRef(({enterPressed, ...props}: any, ref): any => {

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && enterPressed) {
            enterPressed()
        }
    }


    return (
        <CurrencyInput {...props} thousandSeparator={''} decimalSeparator={','} ref={ref} allowNegative={true}
                       onKeyDown={handleKeyDown}/>
    )
})

export default InputMonetario;
