import React from 'react';
import styled from "styled-components";
import {Row} from "../styles/flex";
import {secundaryColor} from "../styles/variables";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusSquare, faMinusSquare} from "@fortawesome/free-regular-svg-icons";
import InputMonetario from "./InputMonetario";

const Counter = ({value = 1, handlePlus, handleMinus, changeValue, type = 'decimal'}: any) => {

    const inputRender = {
        decimal: <InputDinheiro value={value} onChange={changeValue} />,
        inteiro: <InputInteger value={value} onChange={(e) => {
            const value = e.target.value
            changeValue(value.replace(/[^0-9]+/g, ''))
        }} />
    }

    return (
        <CounterStyle>
            {inputRender[type]}
            <Actions>
                <Icon icon={faPlusSquare} onClick={handlePlus} />
                <Icon icon={faMinusSquare} onClick={handleMinus} />
            </Actions>
        </CounterStyle>
    );
};

const CounterStyle = styled.div`
      ${Row};
      justify-content: stretch;
      color: ${secundaryColor};
      padding-left: 3rem;
      user-select: none;
      .actions {

        i {
          cursor: pointer;
          font-size: 1rem;

          &:nth-child(1) {
            margin-bottom: .2rem;
          }
        }

      }
`
const InputDinheiro = styled(InputMonetario)`
font-size: 3.2rem;
width: 10rem;
border: none;
color: ${secundaryColor};
`
const InputInteger = styled.input`
font-size: 3.8rem;
width: 8rem;
border: none;
color: ${secundaryColor};
`

const Actions = styled.div`
 display: flex;
        flex-direction: column;
        margin-left: .5rem;
        justify-content: center;
`

const Icon = styled(FontAwesomeIcon)`
cursor: pointer;
margin-bottom: .2rem;
font-size: 1.8rem;
`

export default Counter;
