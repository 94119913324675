import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {State} from "../store/reducers";
import {Dispatch} from "redux";
import BaseModal from "./BaseModal";
import {Creators as CaixaCreators} from "../store/sagas/caixa";
import Select from "../components/Select";
import Input from "../components/Input";
import {Controller, useForm} from "react-hook-form";
import {toNumber} from "../util/math_util";

const NovaSangriaModal = ({isVisible, naturezas, listaNatureza, closeModal, salvar}) => {

    const {register, handleSubmit, control} = useForm()

    useEffect(() => {
        listaNatureza()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = ({idNaturezaFinanceira, valor}: any) => {
        salvar({idNaturezaFinanceira: toNumber(idNaturezaFinanceira), valor: toNumber(valor)})
    }

    return (
        <BaseModal width={'60%'} isVisible={isVisible} closeModal={closeModal} handleConfirm={handleSubmit(onSubmit)}>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Select label={'Natureza Financeira'} options={naturezas} name={'idNaturezaFinanceira'} defaultValue={''}  ref={register}/>

            <Controller control={control} as={Input} label={'Valor'} containerStyle={{marginTop: '2rem'}} name={'valor'} defaultValue={''}/>
            </form>
        </BaseModal>
    );
};

const mapStateToProps = (state: State) => {
    return {
        isVisible: state.caixa.modalSangriaVisible,
        naturezas: state.caixa.naturezas,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(CaixaCreators.esconderModalSangria()),
    listaNatureza: () => dispatch(CaixaCreators.listNatureza()),
    salvar: (value: any) => dispatch(CaixaCreators.salvaSangria(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NovaSangriaModal);
