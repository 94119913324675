import React, {useState} from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudSunRain, faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {secundaryColor} from "../styles/variables";
import {TextBig} from "../components/Typography";
import {connect} from "react-redux";
import {State} from "../store/reducers";
import {Dispatch} from "redux";
import {addZero, formataData} from "../util/data_util";
import useInterval from "../hooks/useInterval";
import {HoraMinuto} from "../models/hora_minuto.model";


const currenteDate = new Date()

const Topbar = ({cidade, temperatura}) => {

    const [time, setTime] = useState<HoraMinuto>({hora: '00', minuto: '00'})

    const {hora, minuto} = time

    useInterval(() => {
        const date = new Date()
        setTime({hora: addZero(date.getHours()), minuto: addZero(date.getMinutes())})
    }, 1000)

    return (
        <Header>
            <LeftIcon>
                <FontAwesomeIcon icon={faShoppingCart} color={'#fff'} size={'3x'}/>
            </LeftIcon>
            <Content>
                <TextBig>
                    EM &nbsp;ATENDIMENTO
                </TextBig>
                <Datas>
                    <Hora>{hora}:{minuto}</Hora>
                    <SubtitleHora>
                        <div>{formataData(currenteDate)}</div>
                        <div>
                            {cidade}
                            {
                                temperatura ?
                                    <>
                                        , {temperatura}° <FontAwesomeIcon icon={faCloudSunRain} color={secundaryColor}/>
                                    </>
                                    : null
                            }
                        </div>
                    </SubtitleHora>
                </Datas>
            </Content>
            <Logo src={require('../assets/pao-de-acucar.png')}/>
        </Header>
    );
};

const mapStateToProps = (state: State) => {
    return {
        cidade: state?.empresa?.clienteToqsys?.cidade,
        temperatura: state?.configuracao?.temperatura,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);


const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    background-color: rgb(84, 156, 206);
    width: 100vw;
    overflow: hidden;
    box-shadow: 0 .2rem .5rem -.2rem rgba(0,0,0,0.5);
`

const Content = styled.div`
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 2rem;
        color: #fff;
        font-weight: lighter;
`

const Datas = styled.div`
            display: flex;
`

const Hora = styled.div`
    align-self: center;
                margin: 1rem;
                font-size: 3.5rem;
`

const SubtitleHora = styled.div`
  display: flex;
                flex-direction: column;
                justify-content: center;
`

const LeftIcon = styled.div`
        width: 9rem;
        height: 100%;
        background-color: rgb(254, 148, 46);
        display: flex;
        justify-content: center;
        align-items: center;
`

export const Logo = styled.img`
height: 100%;
width: auto;
`
