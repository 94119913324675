import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import {history} from "../../config/storeRoutes";
import {caixa, CaixaTypes} from "../sagas/caixa";
import {pedido, PedidoTypes} from "../sagas/pedido";
import {empresa, EmpresaTypes} from "../sagas/empresa";
import {configuracao, ConfiguracaoTypes} from "../sagas/configuracao";
import {interfaces, InterfacesTypes} from "../sagas/interfaces";
import {pesquisaPreco, PesquisaPrecoTypes} from "../sagas/pesquisaPreco";
import {pesquisaPessoa, PesquisaPessoaTypes} from "../sagas/pesquisaPessoa";

export interface State {
    router: any
    pedido: PedidoTypes
    caixa: CaixaTypes
    empresa: EmpresaTypes
    configuracao: ConfiguracaoTypes
    interfaces: InterfacesTypes
    pesquisaPreco: PesquisaPrecoTypes
    pesquisaPessoa: PesquisaPessoaTypes
}

const reducers = combineReducers({
    router: connectRouter(history),
    pedido,
    caixa,
    empresa,
    configuracao,
    interfaces,
    pesquisaPreco,
    pesquisaPessoa,
})

export default reducers
