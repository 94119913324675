import React from 'react';
import {TOKEN_STORAGE_KEY, URL_API_STORAGE_KEY, URL_BANCO_STORAGE_KEY} from "../config/apiConstants";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {ConfiguracaoCreators} from "../store/sagas/configuracao";

const Login = ({match, history, putConfigInfo}: any) => {

    const parametros: string = match.params.token

    const value = {
        token: parametros.replace(/__.*/, ''),
        urlApi: 'https://api.toqweb.com.br:2004/',
        nomeBanco: parametros.match(/__\w+__/)[0].replace(/_/g, ''),
        idCliente: parametros.match(/\d+$/)[0],
    }

    sessionStorage.setItem(TOKEN_STORAGE_KEY, value.token)
    sessionStorage.setItem(URL_API_STORAGE_KEY, value.urlApi)

    // @ts-ignore
    sessionStorage.setItem(URL_BANCO_STORAGE_KEY, value.nomeBanco)

    history.push('/inicio/escolha-caixa')

    return (
        <div>

        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveConfigs: (value: any) => dispatch(ConfiguracaoCreators.putConfigInfo(value))
})

export default connect(null, mapDispatchToProps)(Login);
