import React from 'react';
import {CardPequeno, CardPequenoSecundary, PageContent, PageTitle, TextSubtitle} from "../../styles/commonStyles";
import styled from "styled-components";
import {RedeAdquirente} from "../../models/rede_adquirente.model";
import {Row} from "../../styles/flex";

const SelecionaListaAdquirentePage = ({redesAdquirentes, handleAdquirente}) => {
    return (
        <PageContent>
            <PageTitle>REDES ADQUIRENTES</PageTitle>
            <Page>
                <TextSubtitle>Escolha a rede adquirente</TextSubtitle>
                <MainContent>
                    {
                        redesAdquirentes.map((redeAdquirente: RedeAdquirente, index: number) =>
                            <RedeAdquirenteCard onClick={handleAdquirente} key={redeAdquirente.id}><Text>{redeAdquirente.nome}</Text></RedeAdquirenteCard>)
                    }
                </MainContent>
                <div>
                    <CardPequeno>
                        <div className="descricao">
                            <span className="item-menu-text">F8</span>
                            <span className="item-menu-text">VER ITENS DA COMPRA</span>
                        </div>
                        <i className="fas fa-eye" />
                    </CardPequeno>
                    <CardPequenoSecundary className={'ajuste-icon-direita'}>
                        <div className="descricao">
                            <span className="item-menu-text">F8</span>
                            <span className="item-menu-text">CANCELAR COMPRA</span>
                        </div>
                        <i className="fas fa-times" />
                    </CardPequenoSecundary>
                </div>
            </Page>
        </PageContent>
    );
};

export default SelecionaListaAdquirentePage;


const Page = styled.div`
   display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        grid-column-gap: 2rem;
        margin-top: 8rem;
`

const MainContent = styled.div`
     display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
`

const RedeAdquirenteCard = styled(CardPequeno)`
${Row};
justify-content: center;
max-height: 10rem;
`

const Text = styled.span`
font-weight: bold;
`
