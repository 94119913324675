import React, {useState} from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {secundaryColor} from "../styles/variables";
import {faCloudSunRain} from '@fortawesome/free-solid-svg-icons'
import {primaryTextColor} from "../styles/typography";
import useInterval from "../hooks/useInterval";
import {addZero, formataData, getDayOfWeek} from "../util/data_util";
import {HoraMinuto} from "../models/hora_minuto.model";
import {State} from "../store/reducers";
import {Dispatch} from "redux";
import {connect} from "react-redux";

const currenteDate = new Date()

const NavMenu = ({cidade, temperatura}) => {

    const [time, setTime] = useState<HoraMinuto>({hora: '00', minuto: '00'})

    const {hora, minuto} = time

    useInterval(() => {
        const date = new Date()
        setTime({hora: addZero(date.getHours()), minuto: addZero(date.getMinutes())})
    }, 1000)

    return (
        <NavMenuStyle>
            <img src={require('../assets/pao-de-acucar.png')} alt="logo" style={{width: '100%', margin: '2rem 0'}}/>
            <TopHeader>
                <div>
                    {cidade}
                    {
                        temperatura ?
                            <>
                                , {temperatura}° <FontAwesomeIcon icon={faCloudSunRain} color={secundaryColor}/>
                            </>
                            : null
                    }
                </div>
                <Hora>{hora}:{minuto}</Hora>
                <div>{getDayOfWeek(currenteDate.getDay())}, {formataData(currenteDate)}</div>
            </TopHeader>
        </NavMenuStyle>
    );
};

const mapStateToProps = (state: State) => {
    return {
        cidade: state?.empresa?.clienteToqsys?.cidade,
        temperatura: state?.configuracao?.temperatura,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);

const NavMenuStyle = styled.div`
background-color: #FFF;
border-right: .1rem solid var(--primary-color-light);
display: flex;
flex-direction: column;
align-items: center;
`

const TopHeader = styled.div`
${primaryTextColor};
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 5rem;
`

const Hora = styled.div`
font-size: 3.8rem;
margin: .5rem 0;
font-weight: lighter;
`
