import React, {useEffect} from 'react';
import styled from "styled-components";

interface SelectOptionType {
    label: string
    value: any
}

const Select = React.forwardRef(({label = '', options = [], ...props}: any, ref) => {

        useEffect(() => {
            options.unshift({label: '-', value: ''})
        }, [options])

        return (
            <SelectContainer>
                <SelectLabel>{label}</SelectLabel>
                <SelectStyled {...props} ref={ref}>
                    {
                        options.map((item: any, index) => <option key={index} value={item.value}>{item.label}</option>)
                    }
                </SelectStyled>
            </SelectContainer>
        );
    }
)

export default Select;

const SelectContainer = styled.div`

`

const SelectLabel = styled.div`
margin-bottom: 1rem;
`

const SelectStyled = styled.select`
width: 100%;
padding: 1rem;
`
