import createSagaMiddleware from 'redux-saga'
import {applyMiddleware, createStore, compose} from "redux"
import reducers from "./reducers"
import rootSaga from "./sagas";
import {history} from "../config/storeRoutes";
import { routerMiddleware } from 'connected-react-router'
import localForage from 'localforage'
import { persistStore, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
];


// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'root',
    storage: localForage,
    stateReconciler: autoMergeLevel2,
}

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, reducers)



const configureStore = () => {
    const store = createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(...middlewares)),
    )

    const persistor = persistStore(store)

    sagaMiddleware.run(rootSaga)

    return {store, persistor}
}

export default configureStore()
