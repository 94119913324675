import React from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusSquare, faMinusSquare} from "@fortawesome/free-regular-svg-icons";
import {secundaryColor} from "../styles/variables";

const QtdItemTable: any = ({quantidade, handlePlus, handleMinus}) => {
    return (
        <QtdItemContainer>
            <span style={{marginRight: '.5rem'}}>{quantidade}</span>
            <Icon icon={faPlusSquare} onClick={handlePlus} size={'1x'} style={{marginRight: '.2rem'}} />
            <Icon icon={faMinusSquare} onClick={handleMinus} size={'1x'} />
        </QtdItemContainer>
    );
};

export default QtdItemTable;

const QtdItemContainer = styled.div`
display: flex;
align-items: center;
`

const Icon = styled(FontAwesomeIcon)`
cursor: pointer;
margin-bottom: .2rem;
font-size: 1.8rem;
color: ${secundaryColor};
`
